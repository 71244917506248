import {defineStore} from 'pinia'
import axios from 'axios'
import {useAuthStore} from "@/store/authStore";
import {fiberPopNode} from "@/models/fiberPopNode";
import {handleMyFiberErrorMessage} from "@/store/util/myFiberErrorMessageHandler";
import {extractLatLngAsString, roundLatLng} from "@/store/util/myFiberDataMapping";
import {myFiberGeoPosition} from "@/models/myFiberGeo";
import {getPopWithDistance} from "@/store/util/geoDataCalculations"
import {fiberSite, fiberSiteAddress} from "@/models/fiberSite";

// @ts-ignore
const baseurl = config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL
const resourceUrl = baseurl + "/resource/pops/"

export type RootState = {
    nodesList: fiberPopNode[],
    filteredNodesList: fiberPopNode[],
    selectedNode?: fiberPopNode,
    token: String,
    config: Object
}
export const useFiberPopNodesStore = defineStore({
    id: 'fiberPopNodes',
    state: () => ({
        nodesList: [],
        filteredNodesList: [],
        selectedNode: null,
        token: "",
        config: {}
    } as RootState),
    getters: {
        popNamesList(state): any {
            const list: any[] = []

            for (const item of state.nodesList) {
                list.push({"value": item.pop_id, "text": item.name});
            }

            return list
        },
        filterBy(state): any {
            return (filter: string) => {
                if (filter == "") {
                    this.filteredNodesList = this.nodesList
                    return this.nodesList
                }

                const filteredNodesList: any[] = []
                for (const popNode of state.nodesList) {

                    const jsonString = JSON.stringify(popNode)
                    const regEx = new RegExp(filter, "i")

                    if (jsonString.search(regEx) > -1) {
                        filteredNodesList.push(popNode)
                    }

                }
                this.filteredNodesList = filteredNodesList
                return filteredNodesList
            }
        },
        getPopNameByID(state): any {
            return (pop_id: string) => {
                for (const pop of state.nodesList) {
                    if (pop.pop_id == pop_id) {
                        return pop.name
                    }
                }

                return ""
            }
        },
        find(state):any {
            return (pop_id: string) => {
                for (const pop of state.nodesList) {
                    if (pop.pop_id == pop_id) {
                        return pop
                    }
                }

                return null
            }
        },
        getSortedByDistance(state): any {
            return (latlng: myFiberGeoPosition)  => {

                const popListByDistance = []

                for (const pop of state.nodesList) {

                    const distancedPop = getPopWithDistance(pop, latlng)
                    if(distancedPop.distance != -1) {
                        popListByDistance.push(distancedPop)
                    }
                }

                popListByDistance.sort(function (a, b) {
                    const x = a.distance;
                    const y = b.distance;
                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                })

                return popListByDistance
            }
        }
    },
    actions: {
        setSelectedNode(node: fiberPopNode) {
            this.selectedNode = node
        },
        unsetSelectedNode() {
            this.selectedNode = null
        },
        load() {

            this.nodesList = []

            const store = useAuthStore()
            this.token = store.token

            this.config = {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    Accept: "application/json",
                    AccessControlAllowOrigin: "*",
                    ContentType: "application/json"
                }
            };

            axios.get(resourceUrl, this.config)
                .then((response) => {
                    response.data.forEach(this.addItem)
                })
                .catch(async (reason) => {

                    if (process.env.VUE_APP_ENV == "locale") {
                        const response = await fetch('test-data/pops.json')
                        const testData = await response.json()
                        testData.forEach(this.addItem)
                    } else {
                        console.log(reason)
                    }
                })
        },
        addItem(item: fiberPopNode) {
            if(item.address == null) {
                item.address = {
                    country: "AT"
                } as fiberSiteAddress
            }
            this.nodesList.push(item)
        },
        getOne(id: string) {
            for (const pop of this.nodesList) {
                if (pop.pop_id == id) {
                    return pop;
                }
            }
            throw new Error("PoP with id " + id + " not found.")
        },
        findIndexById(id: string): number | boolean {
            for (const item of this.nodesList) {
                const index = this.nodesList.indexOf(item);
                if (item.pop_id == id) {
                    return index;
                }
            }
            //console.log(this.nodesList)
            throw new Error("Item to update was not fond in List anymore.<br>Id: " + id)
        },
        getEmptyPop(latlng: any) {

            const geoPosition = roundLatLng(latlng)
            const latlngString = extractLatLngAsString(geoPosition)
            const pop: fiberPopNode = ({
                pop_id: null,
                _etag: null,
                status: "active",
                token: "planned",
                name: null,
                pip: null,
                category: "pop",
                external_id: null,
                customer_reference: null,
                center: latlngString,
                address: {
                    street: null,
                    door: null,
                    country: "AT",
                    zip: null,
                    village: null,
                    city: null
                },
            } as fiberPopNode)

            return pop
        },
        create(popNodeItem: fiberPopNode) {
            if (!popNodeItem.status) {
                popNodeItem.status = "active"
            }
            const popNodeDto = this.mapDTO(popNodeItem)
            return axios.post(resourceUrl, popNodeDto, this.config)
                .then((response) => this.addItem(response.data))
                .catch((error) => {
                    throw new Error("Item could not be created.<br>"
                        + handleMyFiberErrorMessage(error))
                })
        },

        update(popNodeItem: fiberPopNode) {
            const popNodeDto = this.mapDTO(popNodeItem)

            return axios.patch(resourceUrl + popNodeItem.pop_id + "/", popNodeDto, this.config)
                .then(() => {
                    if (popNodeItem.pop_id != null) {
                        const index = this.findIndexById(popNodeItem.pop_id)
                        this.nodesList[index] = popNodeItem
                        this.setSelectedNode(popNodeItem)
                    }
                })
                .catch((error) => {
                    throw new Error("Item Could not be updated.<br>"
                        + handleMyFiberErrorMessage(error))
                })
        },

        remove(popNodeItem: fiberPopNode) {
            return axios.delete(resourceUrl + popNodeItem.pop_id + "/", this.config)
                .then(() => {
                    if (popNodeItem.pop_id != null) {
                        const index = this.findIndexById(popNodeItem.pop_id)
                        this.nodesList.splice(index, 1)
                    }
                })
                .catch((error) => {
                    throw new Error("Item could not be deleted.<br>"
                        + handleMyFiberErrorMessage(error))
                })
        },

        mapDTO(popNodeItem: fiberPopNode): fiberPopNode {
            const fiberPopNodeDoto = ({
                token: popNodeItem.token,
                status: popNodeItem.status,
                pip: popNodeItem.pip,
                name: popNodeItem.name,
                external_id: popNodeItem.external_id,
                customer_reference: popNodeItem.customer_reference,
                center: popNodeItem.center,
                address: popNodeItem.address
            } as fiberPopNode)

            return fiberPopNodeDoto
        }
    }
})


