<template>
    <div>
        <b-card no-body class="mb-1" v-if="show">
            <template #header>
                <div class="d-flex justify-content-between">
                    <span>Site Details</span>
                    <bootstrap-icon icon="x" role="button" @click="close()"></bootstrap-icon>
                </div>
            </template>
            <b-card-text>
                <table class="table table-striped table-hover">
                    <tr>
                        <th>SID</th>
                        <td>
                            <b-button variant="outline-secondary" size="sm" class="pt-1 pb-1"
                                      :id="'popover-target-'+selectedSite.sid"
                                      @click="copyToClipboard(selectedSite.sid, 'Copied SID: ')">
                                <bootstrap-icon icon="person-fill"></bootstrap-icon>
                            </b-button>
                            <b-popover :target="'popover-target-'+selectedSite.sid" triggers="hover" placement="right">
                                <template #title>Site ID</template>
                                {{ selectedSite.sid }}
                                <p class="text-muted">Click to Copy to Clipboard</p>
                            </b-popover>
                        </td>
                    </tr>
                    <tr>
                        <th>Token</th>
                        <td>
                            <token-badge :token="selectedSite.token" reference="site" class="pt-1 pb-1"></token-badge>
                        </td>
                    </tr>
                    <tr>
                        <th>Links</th>
                        <td>
                            <template v-if="selectedSite.links.length > 0">
                                <template v-for="(link,index) in selectedSite.links" :key="index">
                                    <bootstrap-split-badge :variant="mapTokenToVariant(link.token)"
                                                           :headline="'Fiber '+link.fiber"
                                                           :content="link.token"></bootstrap-split-badge>
                                    <br class="m-0 p-0">
                                </template>
                            </template>
                            <b-button v-else variant="outline-primary" size="sm" @click="showModal(site)" class="mx-1"
                                      title="add Links">
                                <bootstrap-icon icon="node-plus"></bootstrap-icon>
                            </b-button>
                        </td>
                    </tr>
                    <tr>
                        <th>Pop(s)</th>
                        <td>
                            <table-cell-list :list="popNames"></table-cell-list>
                        </td>
                    </tr>
                    <tr>
                        <th>PIP</th>
                        <td>
                            <table-cell-list :list="pipsList"></table-cell-list>
                        </td>
                    </tr>
                    <tr>
                        <th>Contracts</th>
                        <td>
                            <table-cell-list :list="contractsList"></table-cell-list>
                        </td>
                    </tr>
                    <tr>
                        <th>Center</th>
                        <td>
                            <b-button variant="outline-secondary" size="sm" class="pt-1 pb-1"
                                      :id="'popover-center-target-'+selectedSite.sid"
                                      @click="copyToClipboard(selectedSite.center, 'Copied Geo Position: ')">
                                <bootstrap-icon icon="geo-alt"></bootstrap-icon>
                            </b-button>
                            <b-popover :target="'popover-center-target-'+selectedSite.sid" triggers="hover"
                                       placement="top">
                                <template #title>Geo Position</template>
                                {{ selectedSite.center }}
                                <p class="text-muted">Click to Copy to Clipboard</p>
                            </b-popover>
                        </td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>
                            <address-cell :address="selectedSite.address"></address-cell>
                        </td>
                    </tr>
                    <tr>
                        <th>LOT</th>
                        <td>{{ selectedSite.lot_id }}</td>
                    </tr>
                    <tr>
                        <th>OAID</th>
                        <td>{{ selectedSite.oaid }}</td>
                    </tr>
                    <tr>
                        <th>Customer Ref.</th>
                        <td>{{ selectedSite.customer_reference }}</td>
                    </tr>
                    <tr>
                        <th>External ID</th>
                        <td>{{ selectedSite.external_id }}</td>
                    </tr>
                </table>
            </b-card-text>
            <template #footer>
                <b-button-group class="float-end">
                    <b-button size="sm" variant="outline-primary" @click="$emit('site-selected', site)"
                              title="go to Site on map">
                        <bootstrap-icon icon="geo-alt"></bootstrap-icon>
                    </b-button>
                    <b-button size="sm" variant="outline-primary" @click="showModal()" title="edit Site">
                        <bootstrap-icon icon="pencil"></bootstrap-icon>
                    </b-button>
                    <b-button size="sm" variant="outline-danger" @click="tryToDeleteSite()" title="delete Site"
                              :disabled="selectedSite.readonly">
                        <bootstrap-icon icon="trash"></bootstrap-icon>
                    </b-button>
                </b-button-group>
            </template>
        </b-card>

        <sites-c-r-u-d-modal ref="sitesModal" :site="editItem"></sites-c-r-u-d-modal>
        <confirm-modal ref="confirmModal" title="Are you sure?"
                       message="Do you want to remove the site?"
                       variant="danger"
                       @confirmed-dialog="removeItem()"
                       ok-button="remove"
                       cancel-button="close"></confirm-modal>
    </div>
</template>

<script>
import TokenBadge from "@/components/atoms/tokenBadge.vue";
import {toast} from "vue3-toastify";
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import ConfirmModal from "@/components/confirmModal.vue";
import AddressCell from "@/components/atoms/address-cell.vue";
import BootstrapSplitBadge from "@/components/atoms/bootstrapSplitBadge.vue";
import SitesCRUDModal from "@/components/resources/sites/sitesCRUDModal.vue";
import {useFiberSitesStore} from "@/store/fiberSitesStore";
import {
    extractPopNamesFromLinks,
    extractPipsFromPipList,
    extractContractsFromLinks,
    mapTokenToVariant
} from "@/store/util/myFiberDataMapping";
import TableCellList from "@/components/atoms/tableCellList.vue";

export default {
    name: "siteDetailView",
    components: {
        TableCellList,
        SitesCRUDModal,
        BootstrapSplitBadge, AddressCell, ConfirmModal, BootstrapIcon, TokenBadge
    },
    data() {
        return {
            fiberSitesStore: useFiberSitesStore(),
            editItem: useFiberSitesStore().selectedSite,
            show: true
        }
    },
    computed: {
        selectedSite() {
            return useFiberSitesStore().selectedSite
        },
        contracts() {
            return extractContractsFromLinks(this.editItem.links)
        },
        contractsList() {
            return extractContractsFromLinks(this.editItem.links) //this.editItem.contracts
        },
        popNames() {
            return extractPopNamesFromLinks(this.editItem.links)
        },
        pipsList() {
            return extractPipsFromPipList(this.editItem)
        }
    },
    watch: {
        selectedSite(latest) {
            if (latest != null) {
                this.editItem = latest
                this.show = true
            }
        }
    },
    methods: {
        mapTokenToVariant,
        close() {
            this.show = false
            useFiberSitesStore().unsetSelectedSite()
        },
        showModal() {
            this.editItem = this.selectedSite
            this.$refs['sitesModal'].showModal()
        },
        tryToDeleteSite() {
            this.editItem = this.selectedSite
            this.$refs['confirmModal'].showModal()
        },
        removeItem() {
            this.fiberSitesStore.remove(this.editItem)
                .catch(error => {
                    toast(error, {
                        dangerouslyHTMLString: true,
                        position: toast.POSITION.BOTTOM_CENTER,
                        type: toast.TYPE.ERROR,
                        icon: true,
                        autoClose: false
                    });
                })
        },
        copyToClipboard(text, headline) {
            // eslint-disable-next-line no-undef
            navigator.clipboard.writeText(text)
            toast(headline + " \n <b>" + text + "</b>", {
                dangerouslyHTMLString: true,
                position: toast.POSITION.BOTTOM_CENTER,
                type: toast.TYPE.SUCCESS,
                icon: true,
                autoClose: 2000,
            });
        },
        selectSite() {
            useFiberSitesStore().setSelectedSite(this.selectedSite)
        }
    }
}
</script>

<style scoped>

.card-text {
    font-size: smaller;
}

.table tr {
    border-bottom: 1px solid #dee2e6;
}

.table tr:nth-child(even) {
    background: #efefef;
}

.table tr:last-of-type {
    border: none
}

.table th, .table td {
    padding: 4px 8px;
}
</style>
