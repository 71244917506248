<template>
    <div :class="modalClass" tabindex="-1" id="nodes-modal" role="dialog" :aria-hidden="!isVisible" :style="style">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit / Create Pop Node: <b>{{ item.name }} - {{ mapAddressShortFormat(item.address) }}</b></h5>
                    <b-button variant="primary" class="close" @click="hideModal()">
                        <bootstrap-icon icon="x"></bootstrap-icon>
                    </b-button>
                </div>
                <div class="modal-body">
                    <b-row>
                        <b-col cols="12">
                            <b-alert variant="warning" v-model="showError" dismissible @dismissed="errorMessage=''"
                                     class="format-message" v-html="errorMessage"></b-alert>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="12">
                            <b-list-group>
                                <b-list-group-item>
                                    <b-form-group label="Token" label-for="token">
                                        <b-form-select v-model="item.token"
                                                       :options="tokenOptions"
                                                       id="token"></b-form-select>
                                    </b-form-group>
                                    <b-form-group label="Name" readonly label-for="sid">
                                        <b-form-input v-model="item.name" type="text" id="sid"></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="PIP" label-for="pip">
                                        <b-form-select v-model="item.pip"
                                                       :options="pipList"
                                                       id="pip"></b-form-select>
                                    </b-form-group>
                                    <b-form-group label="Center" label-for="center">
                                        <b-form-input v-model="item.center" type="text" id="center"></b-form-input>
                                    </b-form-group>
                                </b-list-group-item>
                                <b-list-group-item>
                                    <bootstrap-split-badge variant="secondary" headline="POP ID"
                                                           :content="item.pop_id"></bootstrap-split-badge>
                                    <!--br>
                                    <bootstrap-split-badge variant="secondary" headline="Status"
                                                           :content="item.status"></bootstrap-split-badge-->
                                    <br/>
                                    <bootstrap-split-badge variant="secondary" headline="External ID:"
                                                           :content="item.external_id"></bootstrap-split-badge>
                                    <br/>
                                    <bootstrap-split-badge variant="secondary" headline="Customer Reference"
                                                           :content="item.customer_reference"></bootstrap-split-badge>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                        <b-col md="6" sm="12">
                            <b-list-group>
                                <b-list-group-item v-if="item.address">
                                    <b-form-group label="Center" label-for="center">
                                        <b-form-input v-model="item.center" type="text" id="center"
                                                      readonly></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="Street" label-for="street">
                                        <b-form-input v-model="item.address.street" type="text"
                                                      id="street"></b-form-input>
                                    </b-form-group>
                                    <b-form inline>
                                    <span class="d-flex justify-content-around">
                                        <label for="hnr">Number</label> <label for="door">Door</label>
                                    </span>
                                        <b-input-group>
                                            <b-form-input placeholder="" v-model="item.address.hnr" type="text"
                                                          id="hnr"></b-form-input>
                                            <b-form-input v-model="item.address.door" type="text"
                                                          id="door"></b-form-input>
                                        </b-input-group>
                                    </b-form>
                                    <br/>
                                    <b-form-group label="Village" label-for="village">
                                        <b-form-input v-model="item.address.village" type="text"
                                                      id="port"></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="City" label-for="city">
                                        <b-form-input v-model="item.address.city" type="text"
                                                      id="city"></b-form-input>
                                    </b-form-group>
                                    <span class="d-flex justify-content-around">
                                        <label for="zip" class="w-25">Zip</label>
                                        <label for="city" class="w-75">Country</label>
                                    </span>
                                    <b-input-group class="mb-3">
                                        <b-form-input v-model="item.address.zip" type="text"
                                                      id="zip" class="w-25"></b-form-input>
                                        <b-form-input v-model="item.address.country" readonly type="text"
                                                      id="country" class="w-75"></b-form-input>
                                    </b-input-group>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" v-if="newItem" @click="create">Add item</button>
                    <button type="button" class="btn btn-primary" v-else @click="update">Save changes</button>
                    <button type="button" class="btn btn-secondary" @click="hideModal()" data-dismiss="modal">Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import BootstrapSplitBadge from "@/components/atoms/bootstrapSplitBadge.vue";
import {fiberNodeToken} from "@/models/fiberPopNode";
import {useFiberInfoStore} from "@/store/fiberInfoStore";
import {reactive} from "vue";
import {mapAddressShortFormat} from "../../../store/util/myFiberDataMapping";
import {mapListToFromSelect} from "@/components/utils/vueBootstrapDataStructureSupport";
import {useFiberPipsStore} from "@/store/fiberPipsStore";
import {toast} from "vue3-toastify";

export default {
    name: "nodesCRUDModal",
    components: {BootstrapSplitBadge, BootstrapIcon},
    emits: ["node-updated", "node-created"],
    props: {
        modalId: String,
        node: Object
    },
    data() {
        return {
            nodesStore: useFiberPopNodesStore(),
            infoStore: useFiberInfoStore(),
            item: reactive({...this.node}),
            originalData: reactive({...this.node}),
            address: {},
            isVisible: false,
            errorMessage: "",
        }
    },
    watch: {
        node(latest) {
            if (latest) {
                this.item = reactive({...this.node})
            }
        }
    },
    computed: {
        tokenOptions() {
            return Object.values(fiberNodeToken)
        },
        pipList() {
            return mapListToFromSelect(useFiberPipsStore().pipsList, 'pip', ['name'])
        },
        modalClass() {
            return "modal modal-primary modal-xl fade " + (this.isVisible ? "show" : "")
        },
        style() {
            return this.isVisible ? "display: block; background: rgba(0,0,0, 0.6); " : ""
        },
        newItem() {
            return this.item.pop_id == null
        },
        showError() {
            return this.errorMessage != ""
        },
    },
    methods: {
        mapAddressShortFormat,
        async update() {
            await this.nodesStore.update(this.item)
                .then(() => {
                    this.hideModal()
                    this.$emit("node-updated", this.item)
                })
                .catch(error => {
                    this.errorMessage = error
                    this.item = this.originalData
                    document.getElementById('nodes-modal').scrollTo(0, 0)
                })

        },
        async create() {
            await this.nodesStore.create(this.item)
                .then(() => {
                    this.hideModal()
                    this.$emit("node-created")

                    toast("Pop was created", {
                        dangerouslyHTMLString: true,
                        position: toast.POSITION.BOTTOM_CENTER,
                        type: toast.TYPE.SUCCESS,
                        icon: true,
                        autoClose: 2000,
                    });
                })
                .catch(error => {
                    this.errorMessage = error
                    document.getElementById('nodes-modal').scrollTo(0, 0);
                })
        },
        showModal() {
            this.isVisible = true
        },
        hideModal() {
            this.isVisible = false
        }
    }
}
</script>

<style scoped>
.format-message {
    white-space: pre;
    font-size: 12px;
}

.format-message:first-line {
    font-weight: bold;
    font-size: 14px;
}
</style>
