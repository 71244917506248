import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {useFiberSitesStore} from "@/store/fiberSitesStore";
import {useFiberLinksStore} from "@/store/fiberLinksStore";

const selectNode = function(pop) {
    useFiberPopNodesStore().setSelectedNode(pop)
}

const selectSite = function(site) {
    useFiberSitesStore().setSelectedSite(site)
}
/*
const selectLink = function(link) {
    useFiberLinksStore().setSelectedLink(link)
}*/

export {selectNode, selectSite}
