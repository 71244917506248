import {fiberLink} from "@/models/fiberLink";

const mapFiberLinkExcelDTO = function (link: fiberLink) {
    return {
        token: link.token,
        OAID: link.oaid,
        fiber: link.fiber,
        pop: link.popName,
        contract: link.contract,
        pip: link.pip,
        pop_position: JSON.stringify(link.pop_position),
        external_id: link.external_id
    }
}

export {mapFiberLinkExcelDTO}
