import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withKeys as _withKeys, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b234b16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "main-map-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_l_control_layers = _resolveComponent("l-control-layers")!
  const _component_l_control_attribution = _resolveComponent("l-control-attribution")!
  const _component_context_menu = _resolveComponent("context-menu")!
  const _component_bootstrap_icon = _resolveComponent("bootstrap-icon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_button_group = _resolveComponent("b-button-group")!
  const _component_l_control = _resolveComponent("l-control")!
  const _component_l_tile_layer = _resolveComponent("l-tile-layer")!
  const _component_l_tooltip = _resolveComponent("l-tooltip")!
  const _component_pop_popup = _resolveComponent("pop-popup")!
  const _component_l_circle_marker = _resolveComponent("l-circle-marker")!
  const _component_site_popup = _resolveComponent("site-popup")!
  const _component_l_polyline = _resolveComponent("l-polyline")!
  const _component_l_map = _resolveComponent("l-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_l_map, {
      ref: "map",
      zoom: $data.startZoomLevel,
      center: $data.startCenter,
      id: "main-map",
      class: _normalizeClass($data.cursor),
      "onUpdate:zoom": $options.updateZoomlevel,
      "onUpdate:bounds": $options.updateBounds,
      onClick: $options.onClickedMap,
      onKeyup: _withKeys($options.cancelAdding, ["esc"]),
      onContextmenu: _withModifiers($options.showContextMenu, ["prevent"]),
      options: {zoomControl:true, attributionControl:false, contextMenu: true}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_l_control_layers),
        _createVNode(_component_l_control_attribution, {
          position: "topleft",
          prefix: 'Z: '+$data.currentZoomLevel
        }, null, 8, ["prefix"]),
        _createVNode(_component_context_menu, {
          ref: "context-menu",
          onZoomIn: $options.zoomMap
        }, null, 8, ["onZoomIn"]),
        _createVNode(_component_l_control, {
          position: "topleft",
          title: "Go to my Position"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_button_group, {
              vertical: "",
              class: "shadow bg-white rounded"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_button, {
                  variant: "outline-secondary",
                  size: "sm",
                  onClick: $options.zoomMapToHome
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_bootstrap_icon, { icon: "geo-alt" })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (!$options.resetMarkerAction)
          ? (_openBlock(), _createBlock(_component_l_control, {
              key: 0,
              position: "topright"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_button_group, {
                  vertical: "",
                  class: "shadow bg-white rounded"
                }, {
                  default: _withCtx(() => [
                    ($data.currentZoomLevel>=$data.minSiteZoomlevel)
                      ? (_openBlock(), _createBlock(_component_b_button, {
                          key: 0,
                          variant: "outline-secondary",
                          size: "sm",
                          id: "add-site",
                          onClick: $options.askForGeoPositionSite,
                          title: "add Site"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_bootstrap_icon, { icon: "plus-circle" }),
                            _createTextVNode(" Site ")
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    ($data.currentZoomLevel>=12)
                      ? (_openBlock(), _createBlock(_component_b_button, {
                          key: 1,
                          variant: "outline-secondary",
                          size: "sm",
                          id: "add-pop",
                          onClick: $options.askForGeoPositionPop,
                          title: "add Pop"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_bootstrap_icon, { icon: "plus-circle" }),
                            _createTextVNode(" Pop ")
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ($options.resetMarkerAction)
          ? (_openBlock(), _createBlock(_component_l_control, {
              key: 1,
              position: "bottomright"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_button_group, {
                  class: "shadow bg-white rounded show",
                  id: "confirm-position-group"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_button, {
                      variant: "outline-danger",
                      size: "md",
                      id: "cancel-marker",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.useInteractionStore().deactivateMarkerAction())),
                      classtitle: "cancel"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_bootstrap_icon, { icon: "x" }),
                        _createTextVNode(" Cancel Action ")
                      ]),
                      _: 1
                    }),
                    ($options.newMarkerPosition != null)
                      ? (_openBlock(), _createBlock(_component_b_button, {
                          key: 0,
                          variant: "outline-primary",
                          class: "ms-1",
                          size: "md",
                          id: "confirm-center",
                          onClick: $options.confirmMarker,
                          title: "confirm"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_bootstrap_icon, { icon: "check-circle-fill" }),
                            _createTextVNode(" Confirm New Position ")
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tileLayers, (layer) => {
          return (_openBlock(), _createBlock(_component_l_tile_layer, {
            key: layer.name,
            url: layer.url,
            "layer-type": "base",
            name: layer.name,
            subdomains: $data.sharding,
            useCahe: true,
            crossOrigin: true
          }, null, 8, ["url", "name", "subdomains"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.popMarker, (pop) => {
          return (_openBlock(), _createBlock(_component_l_circle_marker, {
            key: pop.pop_id,
            "lat-lng": $options.getCenter(pop.center),
            radius: $data.currentZoomLevel>=12?16:10,
            weight: $data.currentZoomLevel>=12?4:3,
            opacity: $options.popBorderOpacity(pop),
            color: $options.popColor(pop.token),
            ref_for: true,
            ref: "popMarkerList",
            options: {id: pop.pop_id},
            autoPanOnFocus: "true",
            keepInView: "false",
            draggable: "true",
            onClick: ($event: any) => ($options.selectNode(pop))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_l_tooltip, null, {
                default: _withCtx(() => [
                  _createTextVNode("Pop: " + _toDisplayString(pop.token), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_pop_popup, { pop: pop }, null, 8, ["pop"])
            ]),
            _: 2
          }, 1032, ["lat-lng", "radius", "weight", "opacity", "color", "options", "onClick"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.siteMarker, (site) => {
          return (_openBlock(), _createBlock(_component_l_circle_marker, {
            key: site.sid,
            "lat-lng": $options.getCenter(site.center),
            radius: $options.siteMarkerRadius(site),
            weight: $options.siteMarkerWeight(site),
            color: $options.siteMarkerColor(site),
            opacity: $options.siteMarkerOpacity(site),
            "fill-opacity": $options.siteMarkerFillOpacity(site),
            ref_for: true,
            ref: "siteMarkerList",
            options: {id: site.sid},
            autoPanOnFocus: "true",
            keepInView: "false",
            onClick: ($event: any) => ($options.selectSite(site))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_l_tooltip, null, {
                default: _withCtx(() => [
                  _createTextVNode("Site: " + _toDisplayString(site.token), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_site_popup, { site: site }, null, 8, ["site"])
            ]),
            _: 2
          }, 1032, ["lat-lng", "radius", "weight", "color", "opacity", "fill-opacity", "options", "onClick"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.polyLines, (line) => {
          return (_openBlock(), _createBlock(_component_l_polyline, {
            "lat-lngs": line.points,
            key: line.link_id,
            color: line.color,
            weight: line.weight,
            opacity: line.opacity
          }, {
            default: _withCtx(() => [
              _createVNode(_component_l_tooltip, null, {
                default: _withCtx(() => [
                  _createTextVNode("Link: " + _toDisplayString(line.token), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["lat-lngs", "color", "weight", "opacity"]))
        }), 128)),
        ($options.newMarkerPosition!=null && $options.resetMarkerAction)
          ? (_openBlock(), _createBlock(_component_l_circle_marker, {
              key: 2,
              "lat-lng": $options.getCenter($options.newMarkerPosition),
              radius: 10,
              weight: 4,
              color: "#29ad41",
              opacity: 1,
              "fill-opacity": 0.2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_l_tooltip, null, {
                  default: _withCtx(() => [
                    _createTextVNode("New Position")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["lat-lng", "fill-opacity"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["zoom", "center", "class", "onUpdate:zoom", "onUpdate:bounds", "onClick", "onKeyup", "onContextmenu"])
  ]))
}