import {fiberSiteAddress} from "@/models/fiberSite";

export enum fiberNodeCategory {
    pop = "pop",
    fcp = "fcp"
}

export enum fiberNodeToken {
    planned = "planned",
    built = "built"
}

export interface fiberPopNode {
    pop_id?: string,
    status: string,
    token: fiberNodeToken,
    pip: string,
    name: string,
    external_id?: string, //uuid
    customer_reference?: string,
    center: string //WGS84 Latitude,Longitude,
    address?: fiberSiteAddress,
    distance?: number,
    distanceExpression?: string
}
