<template>
    <b-card :header="headline" class="popup-card" footer-tag="footer">
        <b-card-text>
            <b>{{markerData.name}}</b><br />
            <token-badge :token="markerData.token" reference="node"></token-badge>
            <br />
            <span class="node-id">{{markerData.pop_id}}</span>
        </b-card-text>
        <template #footer v-if="false">
            <b-button variant="primary" @click="clickEdit" class="float-end" size="sm">Edit</b-button>
        </template>
    </b-card>
</template>

<script>
import {BButton, BCard, BCardText} from  'bootstrap-vue-3'
import TokenBadge from "@/components/atoms/tokenBadge.vue";

export default {
    name: "popNodeMarkerPopup",
    components: { TokenBadge, BButton, BCard, BCardText},
    props: {
        content: String,
        markerData: Object,
        id: String
    },
    data: () => {
        return {
        }
    },
    computed: {
        headline() {
            return "Pop Node: "+ this.markerData.name
        }
    },
    methods: {
        clickEdit() {
            this.$emit("edit-node-clicked", this.id)
        }
    }
}
</script>

<style scoped>
.popup-card {
    margin: -14px -25px -14px -21px;
}
.node-id{
    position: relative;
    display: inline-block;
    padding-top: 10px;
    font-size: 12px;

    color: #888888;
}
</style>
