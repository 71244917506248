import {myFiberGeoPosition} from "@/models/myFiberGeo";
import {fiberPopNode} from "@/models/fiberPopNode";

const getPopWithDistance = function (pop: fiberPopNode, latLng: myFiberGeoPosition): fiberPopNode {
    
    if (typeof pop.center == "undefined" || pop.center == null) {
        pop.distance = -1
        return pop
    }

    const popCenter = pop.center.split(",")
    const popLatLng = {
        lat: parseFloat(popCenter[0]),
        lng: parseFloat(popCenter[1])
    } as myFiberGeoPosition
    pop.distance = calculateDistance(latLng, popLatLng)
    pop.distanceExpression = pop.distance.toFixed(2) + " km"

    return pop
}

const calculateDistance = function (point1: myFiberGeoPosition, point2: myFiberGeoPosition): number {
    return optimizedHarversineDistanceInKilometers(point1.lat, point1.lng, point2.lat, point2.lng)
}

//recommended here: https://stackoverflow.com/a/21623206/357234
const optimizedHarversineDistanceInKilometers = function (lat1, lon1, lat2, lon2) {
    const p = 0.017453292519943295;    // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((lat2 - lat1) * p) / 2 +
        c(lat1 * p) * c(lat2 * p) *
        (1 - c((lon2 - lon1) * p)) / 2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

export {getPopWithDistance}
