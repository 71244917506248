<template>
    <b-card :header="headline" class="popup-card" footer-tag="footer">
        <b-card-text>
            <token-badge :token="markerData.token" reference="site"></token-badge>

            <address-cell :address="markerData.address"></address-cell>
            <span class="site-id">{{markerData.sid}}</span>
        </b-card-text>
        <template #footer v-if="false">
            <b-button variant="primary" @click="clickEdit" class="float-end" size="sm">Edit</b-button>
        </template>
    </b-card>
</template>

<script>
import {BButton, BCard, BCardText} from  'bootstrap-vue-3'
import AddressCell from "@/components/atoms/address-cell.vue";
import TokenBadge from "@/components/atoms/tokenBadge.vue";

export default {
    name: "siteMarkerPopup",
    components: { TokenBadge, AddressCell, BButton,  BCard, BCardText},
    props: {
        content: String,
        markerData: Object,
        id: String
    },
    data: () => {
        return {
        }
    },
    computed: {
        headline() {
            return "Site "
        }
    },
    methods: {
        clickEdit() {
            this.$emit("edit-site-clicked", this.id)
        }
    }
}
</script>

<style scoped>
.popup-card {
    margin: -14px -25px -14px -21px;
}
.site-id{
    position: relative;
    display: inline-block;
    padding-top: 10px;
    font-size: 12px;

    color: #888888;
}
</style>
