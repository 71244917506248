import {toast} from "vue3-toastify";

const copyToClipboard = function(text: string, headline: string, type: string = "SUCCESS") {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(text)

    let toastType;
    switch(type) {
        case "SUCCESS":
            toastType = toast.TYPE.SUCCESS
            break
        case "ERROR":
            toastType = toast.TYPE.ERROR
            break
        case "WARNING":
            toastType = toast.TYPE.WARNING
            break
        case "INFO":
            toastType = toast.TYPE.INFO
            break
        case "DEFAULT":
        default:
            toastType = toast.TYPE.DEFAULT
            break
    }

    toast(headline + " \n <b>" + text + "</b>", {
        dangerouslyHTMLString: true,
        position: toast.POSITION.BOTTOM_CENTER,
        type: toastType,
        icon: true,
        autoClose: 2000,
    });
}

export {copyToClipboard}
