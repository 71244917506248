<template>
    <the-header :tenant="fiberInfoStore.tenant"></the-header>
    <b-container fluid>
        <router-view v-slot="{ Component }">
            <Suspense timeout="0">
                <template #default>
                    <component :is="Component"></component>
                </template>
                <template #fallback>
                    <div id="overlay" class="text-center">
                        <b-spinner label="Loading..."></b-spinner>
                    </div>
                </template>
            </Suspense>
        </router-view>
    </b-container>
    <the-footer></the-footer>
</template>

<script>
import TheHeader from "@/components/theHeader.vue";
import TheFooter from "@/components/theFooter.vue";
import {useFiberInfoStore} from "@/store/fiberInfoStore";

export default {
    name: 'App',
    components: {
        TheFooter,
        TheHeader
    },
    setup() {
        useFiberInfoStore().load()
            .then(() => {
                switch( useFiberInfoStore().tenant){
                    case 'kelag':
                        require('@/assets/clients/kelag/ci.css')
                        break;
                    case 'bbooe':
                        require('@/assets/clients/bbooe/ci.css')
                        break;
                    case 'laab':
                        require('@/assets/clients/laab/ci.css')
                        break;
                    case 'noegig':
                        require('@/assets/clients/noegig/ci.css')
                        break;
                    case 'fibereins':
                    default:
                        require('@/assets/clients/fibereins/ci.css')
                }
            })
    },
    data: () => {
        return {
            fiberInfoStore: useFiberInfoStore()
        }
    }
}
</script>

<style>
#overlay{
    background: rgba(128,128,128, 0.7);
    height: 400px;
    padding: 190px 80px 80px;
}
</style>
