import {fiberSite} from "@/models/fiberSite";

const mapFiberSiteCreateDTO = function (siteItem: fiberSite): fiberSite {
    const fiberSiteCreateDTO = ({
        external_id: siteItem.external_id,
        customer_reference: siteItem.customer_reference,
        token: siteItem.token,
        pip: siteItem.pip,
        pop_id: siteItem.pop_id,
        oaid: siteItem.oaid,
        address: siteItem.address,
        lot_id: siteItem.lot_id,
        center: siteItem.center
    } as fiberSite)

    return fiberSiteCreateDTO
}

export {mapFiberSiteCreateDTO}
