import {defineStore} from 'pinia'
import axios from 'axios'
import {useAuthStore} from "@/store/authStore";
import {fiberSite, fiberSiteAddress, fiberSiteToken} from "@/models/fiberSite";
import {useFiberLinksStore} from "@/store/fiberLinksStore";
import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {fiberLink} from "@/models/fiberLink";
import {handleMyFiberErrorMessage} from "@/store/util/myFiberErrorMessageHandler";
import {extractLatLngAsString, roundLatLng} from "@/store/util/myFiberDataMapping";
import {isReadonly} from "vue";
import {useFiberPipsStore} from "@/store/fiberPipsStore";
import {mapFiberSiteDTO} from "@/DTOs/fiberSiteDTO";
import {mapFiberSiteCreateDTO} from "@/DTOs/fiberSiteCreateDTO";

// @ts-ignore
const baseurl = config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL

export type RootState = {
    sitesList: fiberSite[],
    displayedSitesList: fiberSite[],
    filteredSitesList: fiberSite[],
    selectedSite?: fiberSite,
    token: String,
    config: Object,
    allSitesPagesLoaded: boolean
}


export const useFiberSitesStore = defineStore({
    id: 'fiberSites',
    state: () => ({
        sitesList: [],
        displayedSitesList: [],
        filteredSitesList: [],
        selectedSite: null,
        token: "",
        config: {},
        allSitesPagesLoaded: false
    } as RootState),
    getters: {
        latest(state): any {
            return state.sitesList[state.sitesList.length - 1]
        },
        filterBy(state): any {
            return (filter: string) => {
                if (filter == "") {
                    this.filteredSitesList = this.sitesList
                    return this.sitesList
                }

                const filteredSitesList: any[] = []
                for (const site of state.sitesList) {

                    const jsonString = JSON.stringify(site)
                    const regEx = new RegExp(filter, "i")

                    if (jsonString.search(regEx) > -1) {
                        filteredSitesList.push(site)
                    }
                }
                this.filteredSitesList = filteredSitesList
                return filteredSitesList
            }
        },
        find(state): any {
            return (sid: string) => {
                for (const item of this.sitesList) {
                    if (item.sid == sid) {
                        return item;
                    }
                }
            }
        },
    },
    actions: {
        setSelectedSite(site: fiberSite){
            this.selectedSite = site
        },
        unsetSelectedSite(){
            this.selectedSite = null
        },
        authenticate() {
            const store = useAuthStore()
            this.token = store.token

            this.config = {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    Accept: "application/json",
                    AccessControlAllowOrigin: "*",
                    ContentType: "application/json"
                }
            };
        },
        loadInBoundingBox(boundingBox: any) {
            const url: string = baseurl + "/resource/sites/" + "?bbox=" + this.extractBoundingBox(boundingBox)
            const store = useAuthStore()
            this.token = store.token

            this.config = {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    Accept: "application/json",
                    AccessControlAllowOrigin: "*",
                    ContentType: "application/json"
                }
            };

            axios.get(url, this.config)
                .then(async (response) => {
                    this.displayedSitesList = []
                    response.data.forEach(this.addDisplayItem)
                    this.filteredSitesList = this.displayedSitesList
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        extractBoundingBox(boundingBox: any) {
            return boundingBox._northEast.lat + "," + boundingBox._northEast.lng + "," + boundingBox._southWest.lat + "," + boundingBox._southWest.lng
        },
        addOneSiteToCurrent(sid: string): void {
            for (const site of this.sitesList) {
                if (site.sid == sid) {
                    this.addDisplayItem(site)
                }
            }
        },
        addDisplayItem(item: fiberSite) {
            item.links = useFiberLinksStore().getBySID(item.sid) || []
            if (item.contracts == null) {
                item.contracts = []
            }
            item.popName = useFiberPopNodesStore().getPopNameByID(item.pop_id)
            this.displayedSitesList.push(item)
        },
        loadAll(page?: number) {
            const store = useAuthStore()
            this.token = store.token

            this.config = {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    Accept: "application/json",
                    AccessControlAllowOrigin: "*",
                    ContentType: "application/json"
                }
            };

            if (!page) {
                this.sitesList = []
                page = 0
            }
            page++
            const url: string = baseurl + "/resource/sites/" + "?_size=500" + "&_page=" + (page)

            axios.get(url, this.config)
                .then(async (response) => {
                    response.data.forEach(this.addItem)

                    // @ts-ignore
                    if (response.data.length > 0) {
                        useFiberSitesStore().loadAll(page)
                    } else {
                        this.allSitesPagesLoaded = true
                    }
                })
                .catch((error) => {
                    console.log(error)
                })

            return this.sitesList;
        },

        addItem(item: fiberSite) {

            item.links = []
            if (item.sid != null) {
                item.links = useFiberLinksStore().getBySID(item.sid)
            }
            if (item.contracts == null) {
                item.contracts = []
            }

            try {
                for(const fiberPip of useFiberPipsStore().pipsList) {
                    if(fiberPip.pip == item.pip) {
                        item.readonly = fiberPip.readonly
                    }
                }
            } catch(error) {
               item.readonly = false
            }
            item.popName = useFiberPopNodesStore().getPopNameByID(item.pop_id)
            this.sitesList.push(item)
        },

        pushLink(site: fiberSite, link: fiberLink) {
            const siteIndex = this.findIndexById(site.sid)
            this.sitesList[siteIndex].links.push(link)
        },

        getOne(id: string): fiberSite {
            for (const item of this.sitesList) {

                if (item.sid == id) {
                    // console.log(item.sid, id)
                    return item;
                } else {
                    console.log(typeof item.sid, typeof id)
                }
            }

            throw new Error("Site with id " + id + " not found.")
        },
        findIndexById(id: string, list:any = this.sitesList): number | boolean {
            for (const item of list) {
                const index = list.indexOf(item);
                if (item.sid == id) {
                    return index;
                }
            }
            //console.log("SID not found: "+id)
            //console.log(this.sitesList)
            throw new Error("Item to update was not fond in list anymore")
        },
        fillFilteredList() {
            this.filteredSitesList = this.sitesList
        },
        getEmptySite(latlng: any) {
            const geoPosition = roundLatLng(latlng)
            const latlngString = extractLatLngAsString(geoPosition)

            const site: fiberSite = ({
                sid: null,
                _etag: null,
                status: "started",
                token: "reserved",
                oaid: null,
                external_id: null,
                pop_id: null,
                pip: null,
                contracts: [],
                customer_reference: null,
                lot_id: null,
                address: {
                    street: null,
                    door: null,
                    country: "AT",
                    zip: null,
                    village: null,
                    city: null
                },
                center: latlngString,
                readonly: false
            } as fiberSite)

            return site
        },
        create(siteItem: fiberSite) {
            const createSiteItemDto = mapFiberSiteCreateDTO(siteItem)
            return axios.post(baseurl + "/resource/sites/", createSiteItemDto, this.config)
                .then((response) => {
                    const newSite = response.data
                    this.addItem(newSite)
                    const index = this.findIndexById(newSite.sid)
                    this.sitesList[index] = newSite
                    this.filteredSitesList.push(newSite)
                    this.setSelectedSite(newSite)
                })
                .catch((error) => {
                    throw new Error("Item could not be created.<br>"
                        + handleMyFiberErrorMessage(error))
                })
        },
        update: function (siteItem: fiberSite) {
            const siteItemDto = this.mapDTO(siteItem)

            return axios.patch(baseurl + "/resource/sites/" + siteItem.sid + "/", siteItemDto, this.config)
                .then(() => {
                    if (siteItem.sid != null) {
                        const index = this.findIndexById(siteItem.sid)
                        this.sitesList[index] = siteItem
                        const indexFilter = this.findIndexById(siteItem.sid, this.filteredSitesList)
                        this.filteredSitesList[indexFilter] = siteItem
                        this.setSelectedSite(siteItem)
                    }
                })
                .catch((error) => {
                    throw new Error("Item could not be updated.<br>"
                        + handleMyFiberErrorMessage(error))
                })
        },
        remove(siteItem: fiberSite) {
            return axios.delete(baseurl + "/resource/sites/" + siteItem.sid + "/", this.config)
                .then(() => {
                    if (siteItem.sid != null) {
                        const index = this.findIndexById(siteItem.sid)
                        this.sitesList.splice(index, 1)
                    }
                })
                .catch((error) => {

                    throw new Error("Item could not be deleted.<br>"
                        + handleMyFiberErrorMessage(error))
                })
        },
        //removed contract from list, as check with docs on 6.4.2023
        mapDTO(siteItem: fiberSite): fiberSite {
            return mapFiberSiteDTO(siteItem)
        }
    }
})


