<template>
    <div>
        <b-table-simple ref="nodeTable" responsive>
            <b-thead>
                <b-th>Token</b-th>
                <b-th>Name</b-th>
                <b-th>PIP</b-th>
            </b-thead>
            <b-tbody v-if="nodesList.length > 0">
                <template v-for="(node, index) in pagedList" :key="index">
                    <b-tr @click="onRowSelected(node)" :class="getClass(index, node)">
                        <b-td>
                            <token-badge :token="node.token" reference="node" small></token-badge>
                        </b-td>
                        <b-td>{{ node.name }}</b-td>
                        <b-td class="text-nowrap">{{ node.pip }}</b-td>
                    </b-tr>
                </template>
            </b-tbody>
        </b-table-simple>
        <pagination-bar :total="nodesList.length" :page-size="pageSize"
                        :starting-page="currentPage" @select-page="onPageChange"></pagination-bar>
    </div>
</template>

<script>
import {toast} from "vue3-toastify";
import TokenBadge from "@/components/atoms/tokenBadge.vue";
import PaginationBar from "@/components/molecules/paginationBar.vue";

export default {
    name: "nodesTable",
    components: {PaginationBar, TokenBadge},
    props: {
        fiberNodeStore: Object,
        pageSize: Number,
        geoPositionForNewItem: Object
    },
    data() {
        return {
            currentNode: null,
            node: {},
            currentPage: 1,
            nodesFields: [
                {
                    key: 'token',
                    sortable: true
                },
                {
                    key: "name",
                    sortable: true
                },
                {
                    key: 'pip',
                    sortable: true
                }
            ],
        }
    },
    computed: {
        totalRows() {
            return this.nodesList.length
        },
        nodesList() {
            if (this.fiberNodeStore.filteredNodesList.length > 0) {
                return this.fiberNodeStore.filteredNodesList
            } else {
                return this.fiberNodeStore.nodesList
            }
        },
        pagedList() {
            let start = (this.currentPage - 1) * this.pageSize
            return this.nodesList.slice(start, start + this.pageSize)
        },
        selectedPop() {
            return this.fiberNodeStore.selectedNode
        }
    },
    watch: {
        nodesList(latest, older) {
            if (latest != older) {
                this.currentPage = 1
            }
        }
    },
    methods: {
        onPageChange(page) {
            this.currentPage = page
        },
        onRowSelected(item) {
            if (item.center == null) {
                toast("No GEO Position found for node: " + item.name + ".", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    type: toast.TYPE.ERROR,
                    icon: true,
                    autoClose: 2000,
                })
            }

            this.fiberNodeStore.setSelectedNode(item)
            this.currentNode = item
        },
        getClass(index, node){

            let classes = index % 2 == 0 ? 'tr-even':'tr-odd'

            if(this.selectedPop) {
                if(node.pop_id == this.selectedPop.pop_id) {
                    classes += " tr-selected"
                }
            }

            return classes
        },
    }
}
</script>

<style scoped>

td{
    font-size: small;
}


tr.tr-odd{
    background: var(--bs-table-striped-bg)
}

tr.tr-selected {
    background: var(--bs-table-active-bg)
}
</style>
