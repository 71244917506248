<template>
    <b-container fluid>
        <footer class="footer">
            (c) {{thisYear}} FiberEins
            <p id="version-tag">{{versionTag}}</p>
        </footer>
        <div class="ribbon" v-if="env!='production'">
            <a href="#">{{env}}</a>
        </div>

    </b-container>
</template>

<script>
export default {
    name: "theFooter",
    data: () => {
        return {
            env: "locale"
        }
    },
    computed: {
        thisYear() {
            let date = new Date()
            return date.getFullYear()
        },
        versionTag() {
            // eslint-disable-next-line no-undef
            let versionVar = VUE_APP_VERSION_TAG || process.env.VUE_APP_VERSION_TAG
            let index = versionVar.indexOf("-", 9)

            if(index >= 0) {
                return versionVar.slice(0,index)
            }

            return versionVar
        }
    },
    mounted() {

        // eslint-disable-next-line no-undef
        this.env = config.VUE_APP_ENV || process.env.VUE_APP_ENV
        console.log("version: " + this.versionTag)
    }
}
</script>

<style scoped>
.footer {
    border-top: 1px solid #ccc;
    background: #efefef;
    color: #888;
    padding: 30px;
    text-align: center;
}

.ribbon {
    background-color: #a00;
    overflow: hidden;
    white-space: nowrap;
    /* top left corner */
    position: absolute;
    left: -36px;
    top: 13px;
    /* for 45 deg rotation */
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    /* for creating shadow */
    -webkit-box-shadow: 0 0 10px #888;
    -moz-box-shadow: 0 0 10px #888;
    box-shadow: 0 0 10px #888;
}
.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 100% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    /* for creating shadow */
    text-shadow: 0 0 5px #444;
}

#version-tag{
    margin-bottom: 0px;
    font-size: 10px;
    color: #aaa;
}
</style>
