<template>
    <div class="d-flex flex-fill m-5 mb-0 mt-0 input-group">
        <input :class="inputFieldClass" type="search" placeholder="Search" aria-label="Search" v-model="query"
               @focus="show=true">
        <b-dropdown right variant="outline-primary" text="Export Excel">
            <b-dropdown-item @click="exportSitesExcel">
                Visible Sites
                <div class="text-muted small">
                    (all sites in the current view)
                </div>
            </b-dropdown-item>
            <b-dropdown-item @click="exportPopsExcel">
                Visible Pops
                <div class="text-muted small">
                    (all Pops in the current view)
                </div></b-dropdown-item>
            <b-dropdown-item @click="exportLinksExcel">
                Visible Links
                <div class="text-muted small">
                    (all links in the current view)
                </div>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="exportResourcesExcel">
                Visible Resources
                <div class="text-muted small">
                    (all resources in the current view)
                </div>
            </b-dropdown-item>
            <b-dropdown-item @click="exportAllResoucrcesExcel" :disabled="!useFiberSitesStore().allSitesPagesLoaded">
                All Resources <bootstrap-icon v-if="!useFiberSitesStore().allSitesPagesLoaded" icon="arrow-repeat" animation="spin" ></bootstrap-icon>
                <div class="text-muted small">
                    (all resources available)
                </div>
            </b-dropdown-item>
        </b-dropdown>
        <button class="btn btn-outline-secondary rounded-end" type="button" @click="query=''">Clear</button>
        <b-button class="ms-2 rounded" variant="outline-primary" @click="openSitesImportModal">Import</b-button>
        <b-list-group v-if="searchResults.length > 0 && show" id="suggestionList">
            <b-list-group-item v-for="(result, index) in searchResults" :key="index" @click="acceptResult(result)"
                               class="d-flex justify-content-between">
                <div>
                    <span v-html="highlightSearchQuery(result.textSuggestion)"></span><br/>
                    <span class="text-muted small">
                        {{ result.type }}
                        <template v-if="result.type == 'Site' || result.type == 'Link'">
                            - OAID: <span v-html="highlightSearchQuery(result.resource.oaid, 'strong')"></span>
                        </template>
                    </span>
                </div>
                <div class="ps-4 pt-2">
                    <bootstrap-icon :icon="getIconByType(result.type)"></bootstrap-icon>
                </div>
            </b-list-group-item>
            <b-list-group-item id="helper" variant="secondary" class="d-flex justify-content-between">
                <div class="text-muted small">
                    Click on any result to open it on the map and zoom to level 12.
                </div>
                <div title="Close Suggestion List">
                    <bootstrap-icon icon="x" role="button" @click="show=false"></bootstrap-icon>
                </div>
            </b-list-group-item>
        </b-list-group>
        <import-modal ref="sitesImportModal"></import-modal>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {useDorisSearchStore} from "@/store/dorisSearchStore";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons"
import {BListGroup, BListGroupItem} from "bootstrap-vue-3";
import {exportSitesExcel, exportPopsExcel, exportLinksExcel, exportResourcesExcel, exportAllResoucrcesExcel} from "@/components/utils/excelExport";
import ImportModal from "@/components/siteImporter/importModal.vue";
import {useFiberSitesStore} from "@/store/fiberSitesStore";

export default defineComponent({
    name: "searchBar",
    components: {ImportModal, BListGroupItem, BListGroup, BootstrapIcon},
    data() {
        return {
            query: "",
            store: useDorisSearchStore(),
            show: false
        }
    },
    watch: {
        query(latest, older) {
            if (latest != older && latest != "") {
                this.store.findByQuery(this.query)
                this.show = true
            }

            if (latest == "") {
                this.show = false
                this.store.clearSearch()
            }
        }
    },
    computed: {
        searchResults() {
            return useDorisSearchStore().searchResult
        },
        inputFieldClass() {
            return (this.show && this.searchResults.length > 0 ? "open-list" : "") + " form-control me-0"
        }
    },
    methods: {
        useFiberSitesStore,
        exportSitesExcel, exportPopsExcel, exportLinksExcel, exportResourcesExcel, exportAllResoucrcesExcel,
        acceptResult(result) {
            this.store.selectResult(result)
            this.show = false
        },
        getIconByType(type) {
            switch (type) {
                case "Site":
                    return "person-fill"
                case "Pop":
                    return "star-fill"
                case "Link":
                    return "hdd-network"
                case "Address":
                    return "geo-alt"
                default:
                    return ""
            }
        },
        highlightSearchQuery(resultString, tag) {
            if (!resultString) {
                return ""
            }
            if (!tag) {
                tag = "strong"
            }
            const regEx = new RegExp(this.query, "i")
            return resultString.replace(regEx, `<${tag}>${this.query}</${tag}>`)
        },
        openSitesImportModal() {
            this.$refs['sitesImportModal'].showModal()
        }
    }
})
</script>

<style scoped>

.open-list {
    border-bottom-left-radius: 0px;
}

#suggestionList {
    position: absolute;
    z-index: 1050;
    margin-top: 45px;

    border-color: #86b7fe;

    box-shadow: 0px 0px 0px 0.25rem rgb(13 110 253 / 25%);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

#suggestionList .list-group-item {
    cursor: pointer;
    padding-right: 24px;
    min-width: 445px
}

#suggestionList #helper.list-group-item {
    cursor: default;
}


#suggestionList .list-group-item:hover {
    background-color: #efefef
}

.dropdown-item .text-muted {
    font-size: 10px;
    line-height: 14px;
}

.dropdown-item:active .text-muted {
    color: #fff !important;
}

</style>
