import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_site_marker_popup = _resolveComponent("site-marker-popup")!
  const _component_l_popup = _resolveComponent("l-popup")!

  return (_openBlock(), _createBlock(_component_l_popup, null, {
    default: _withCtx(() => [
      _createVNode(_component_site_marker_popup, {
        "marker-data": _ctx.site,
        id: _ctx.site.sid
      }, null, 8, ["marker-data", "id"])
    ]),
    _: 1
  }))
}