import {defineStore} from 'pinia'
import {fiberSite} from "@/models/fiberSite";
import {useFiberSitesStore} from "@/store/fiberSitesStore";
import {useFiberLinksStore} from "@/store/fiberLinksStore";

export const useSiteImportStore = defineStore({
    id: 'siteImportStore',
    state: () => ({
        sites: []
    }),
    actions: {
        storeSites(sites: fiberSite[]) {
            this.sites = sites
        },
        uploadSitesAndLinks() {
            return new Promise((resolve, reject) => {
                let index = -1
                const sitesLength = this.sites.length -1

                function manipulateNext() {
                    if (index < sitesLength) {
                        index++
                        useSiteImportStore().uploadSite(index)
                            .then(() => manipulateNext())
                            .catch(error => reject(error))
                    } else {
                        resolve(1)
                    }
                }

                manipulateNext();
            })
        },
        async uploadSite(index) {

            await useFiberSitesStore().authenticate()

            const newSite = this.sites[index]
            await useFiberSitesStore().create(newSite)
                .then(async () => {
                    const newSid = useFiberSitesStore().latest.sid
                    const newLink = this.sites[index].links[0]
                    newLink.sid = newSid

                    await useFiberLinksStore().create(newLink)
                        .then(() => {
                            this.sites[index].links[0] = newLink
                            this.sites[index].sid = newSid
                        })
                }).catch(error => {
                    throw new Error(error)
                })
        }
    }
})
