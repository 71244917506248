

import "leaflet/dist/leaflet.css"

import {leafletBounds} from "@/models/leafletBounds"
import {fiberPopNode} from "@/models/fiberPopNode"
import {fiberSite} from "@/models/fiberSite"
import {extractDataFromServiceTemplate, getCenter} from "@/components/map/utils/leaflet"
import {linkLineColor, popColor, siteColor} from "@/components/map/utils/staticComputedProperties"
import {selectNode, selectSite} from "@/components/map/utils/storeActions"
import {toast} from "vue3-toastify"

import {
    LCircleMarker,
    LControl,
    LControlAttribution,
    LControlLayers,
    LMap,
    LPolyline,
    LTileLayer,
    LTooltip
} from "@vue-leaflet/vue-leaflet"
import PopPopup from "@/components/map/children/PopPopup.vue"
import SitePopup from "@/components/map/children/SitePopup.vue"
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons"

import axios from "axios"
import {useFiberSitesStore} from "@/store/fiberSitesStore"
import ContextMenu from "@/components/map/children/contextMenu.vue"
import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {useInteractionStore} from "@/store/interactionStore";
import {ref} from "vue";
import {useFiberLinksStore} from "@/store/fiberLinksStore";

export default {
    name: "mapView",
    components: {
        ContextMenu,
        LCircleMarker, LControl, LControlAttribution, LControlLayers, LMap, LPolyline, LTileLayer, LTooltip,
        PopPopup, SitePopup, BootstrapIcon
    },
    props: {
        popMarker: {
            type: Array as () => Array<fiberPopNode>,
            default: () => []
        },
        siteMarker: {
            type: Array as () => Array<fiberSite>,
            default: () => []
        },
        wmtsUrl: {type: String, required: true},
        layers: {type: Array, required: true},
    },
    async setup(props) {
        useInteractionStore().loadMapPosition()

        const tileLayers = ref([])

        await axios.get(props.wmtsUrl)
            .then((response) => {

                for (let layerName of props.layers) {
                    let layerUrl = extractDataFromServiceTemplate(response.data, layerName)
                    tileLayers.value.push({
                        //@ts-ignore
                        url: layerUrl,
                        //@ts-ignore
                        name: layerName
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })

        return {tileLayers}
    },
    mounted() {

        if(this.currentZoomLevel >= this.minSiteZoomlevel) {

            this.$watch('$refs.map.ready', (newValue, oldValue) => {
                if (newValue === true && oldValue === false) {
                    const bounds = this.$refs['map'].leafletObject.getBounds()
                    this.$emit('update:bounding-box', bounds)
                }
            });
        }
    },
    data() {
        return {
            startCenter: useInteractionStore().mapPosition.center,
            startZoomLevel: useInteractionStore().mapPosition.zoom,
            minSiteZoomlevel: 15,
            minPopZoomlevel: 12,
            currentZoomLevel: useInteractionStore().mapPosition.zoom,
            sharding: ["maps", "maps1", "maps2", "maps3", "maps4"],
            overlay: "",
            cursor: "leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom",
            currentBounds: Object as () => leafletBounds,
            polyLines: []
        }
    },
    computed: {
        selectedPop() {
            return useFiberPopNodesStore().selectedNode
        },
        selectedSite() {
            return useFiberSitesStore().selectedSite
        },
        resetMarkerAction() {
            return useInteractionStore().resetMarkerAction
        },
        newMarkerPosition() {
            return useInteractionStore().newMarkerPosition
        },
        positionConfirmed() {
            return useInteractionStore().positionConfirmed
        }
    },
    watch: {
        selectedSite(latest) {
            if(latest && latest.center) {
                this.openSiteMarker(latest)
                this.displayLinks(latest.links)
            }
        },
        selectedPop(latest) {
            if(latest && latest.center) {
                this.openPopMarker(latest)
                const linksList = useFiberLinksStore().getWherePopId(latest.pop_id)
                this.displayLinks(linksList)
            }
        },
        resetMarkerAction(latest) {
            if(latest) {
                this.zoomToMarker(this.selectedSite, 17)
                this.$nextTick( () => {
                    let siteMarker: any
                    siteMarker = this.$refs['siteMarkerList'].find((marker: any) => marker.options.id == this.selectedSite.sid);
                    this.cursor = "leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom crosshair-cursor-enabled"
                    siteMarker.leafletObject.closePopup();
                })
            }
        },
    },
    methods: {
        siteColor, popColor, getCenter,
        selectNode, selectSite, useInteractionStore,
        popBorderOpacity(pop) {
            const filteredList = useFiberPopNodesStore().filteredNodesList
            if(filteredList.length > 0 && !filteredList.includes(pop)) {
                return 0.4
            } else {
                return 1
            }
        },
        siteMarkerRadius(site) {
            if(this.resetMarkerAction) {
                if(site.sid == this.selectedSite.sid) {
                    return 10
                } else {
                    return 8
                }
            }

            return 8
        },
        siteMarkerWeight(site){
            if(this.resetMarkerAction) {
                if(site.sid == this.selectedSite.sid) {
                    return 4
                } else {
                    return 1
                }
            }
            return 2
        },
        siteMarkerColor(site){
            if(this.resetMarkerAction) {
                if(site.sid == this.selectedSite.sid) {
                    return this.newMarkerPosition == null ? '#f66802': '#981d08'
                } else {
                    return '#000000'
                }
            }

            return siteColor(site.token)
        },
        // eslint-disable-next-line no-unused-vars
        siteMarkerOpacity(site) {
            if (this.currentZoomLevel<this.minSiteZoomlevel) {
                return 0
            } else {
                return 1
            }
        },
        // eslint-disable-next-line no-unused-vars
        siteMarkerFillOpacity(site) {
            return this.currentZoomLevel>=12?0.4:0
        },
        zoomMap(latLng, zoomLevel) {
            if(zoomLevel) {
                this.$refs['map'].leafletObject.setView(latLng, zoomLevel)
            } else {
                this.$refs['map'].leafletObject.setView(latLng, this.currentZoomLevel + 4)
            }
        },
        zoomToMarker(itemWithCenter, zoomLevel) {
            const center = itemWithCenter.center.split(",")
            const latLng = [center[0], center[1]]
            this.$refs['map'].leafletObject.setView(latLng, zoomLevel)
        },
        zoomMapToHome() {
           navigator.geolocation.getCurrentPosition((position) => {
               const latLng = [position.coords.latitude, position.coords.longitude]
               this.$refs['map'].leafletObject.setView(latLng, this.minPopZoomlevel + 2)
           })
        },
        openSiteMarker(site) {
            if (this.currentZoomLevel < this.minSiteZoomlevel) {
                this.$refs['map'].leafletObject.setZoom(this.minSiteZoomlevel)
            }

            let siteMarker: any
            try{
                siteMarker = this.$refs['siteMarkerList'].find((marker: any) => marker.options.id == site.sid);
                siteMarker.leafletObject.openPopup();
            } catch(e) {
                if(site.sid == null) {
                    useFiberSitesStore().addItem(site)
                }
                useFiberSitesStore().addOneSiteToCurrent(site.sid)
                this.$nextTick(() => {
                    siteMarker = this.$refs['siteMarkerList'].find((marker: any) => marker.options.id == site.sid)
                    siteMarker.leafletObject.openPopup();
                })
            }
            useFiberPopNodesStore().unsetSelectedNode()
        },
        openPopMarker(pop: any){

            if (this.currentZoomLevel < this.minPopZoomlevel) {
                this.$refs['map'].leafletObject.setZoom(this.minPopZoomlevel)
            }
            let popMarker: any
            try{
                popMarker = this.$refs['popMarkerList'].find((marker: any) => marker.options.id == pop.pop_id);
                popMarker.leafletObject.openPopup();
            } catch (e) {
                useFiberPopNodesStore().addItem(pop)
                this.$nextTick(() => {
                    popMarker = this.$refs['popMarkerList'].find((marker: any) => marker.options.id == pop.pop_id)
                    popMarker.leafletObject.openPopup();
                })
            }
            useFiberSitesStore().unsetSelectedSite()
        },
        updateZoomlevel(zoomLevel: number) {
            this.currentZoomLevel = zoomLevel
        },
        askForGeoPositionSite() {

            toast("Please click on the map to select the geo position. Press ESC to cancel.", {
                dangerouslyHTMLString: false,
                position: toast.POSITION.TOP_CENTER,
                type: toast.TYPE.INFO,
                icon: true,
                autoClose: false
            });

            this.cursor = "leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom crosshair-cursor-enabled"

            setTimeout(() => this.overlay = "site", 200)

        },
        askForGeoPositionPop(){

            toast("Please click on the map to select the geo position. Press ESC to cancel.", {
                dangerouslyHTMLString: false,
                position: toast.POSITION.TOP_CENTER,
                type: toast.TYPE.INFO,
                icon: true,
                autoClose: false
            });

            this.cursor = "leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom crosshair-cursor-enabled"

            setTimeout(() => this.overlay = "pop", 200)

        },
        onClickedMap(e) {

            if(this.resetMarkerAction) {
                const position = this.$refs['map'].leafletObject.mouseEventToLatLng(e)
                useInteractionStore().setNewMarkerPosition(position)
            }

            if (this.overlay != "") {
                toast.remove()
                this.cursor = "leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom"
                switch (this.overlay) {
                    case "site":
                        this.$emit("add-site", this.$refs['map'].leafletObject.mouseEventToLatLng(e))
                        break
                    case "pop":
                        this.$emit("add-pop", this.$refs['map'].leafletObject.mouseEventToLatLng(e))
                        break
                }
                this.overlay = ""
            }
        },
        cancelAdding() {
            toast.remove()
            this.cursor = "leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom"
            this.overlay = ""
        },
        confirmMarker() {
            useInteractionStore().setPositionConfirmed()
            useInteractionStore().deactivateMarkerAction()
            this.cursor = "leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom"
        },
        displayLinks(linksList) {
            this.polyLines = []

            for(const link of linksList) {
                const site = useFiberSitesStore().find(link.sid)
                const pop = useFiberPopNodesStore().find(link.pop_id)
                if(pop != null){
                    const lineConfig = {
                        points: [site.center.split(","), pop.center.split(",")],
                        link_id: link.link_id,
                        color: linkLineColor(link.token),
                        weight: 2,
                        opacity: 1,
                        token: link.token
                    }
                    this.polyLines.push(lineConfig)
                }
            }
        },
        updateBounds(bounds){

            useInteractionStore().setMapPosition(this.$refs['map'].leafletObject.getCenter(), this.currentZoomLevel)

            if ( this.currentBounds ||
                this.currentZoomLevel == this.minSiteZoomlevel - 3 ||
                (this.currentZoomLevel >= this.minSiteZoomlevel - 3 &&
                    (this.isOutOfBounds(bounds) ||
                        this.$refs['siteMarkerList'].length == 0)
                )
            ) {
                this.$emit('update:bounding-box', bounds)
                this.currentBounds = bounds
            }
        },
        isOutOfBounds(bounds: leafletBounds) {
            return !this.currentBounds ||
                (bounds._northEast.lat < this.currentBounds._northEast.lat || bounds._northEast.lng < this.currentBounds._northEast.lng) ||
                (bounds._southWest.lat > this.currentBounds._southWest.lat || bounds._southWest.lng > this.currentBounds._southWest.lng)
        },
        showContextMenu(e) {
            const latLng = this.$refs['map'].leafletObject.mouseEventToLatLng(e)
            this.$refs['context-menu'].open(latLng)
        }
    }
}
