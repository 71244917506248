import {fiberLink} from "@/models/fiberLink";

export enum fiberSiteToken {

    reserved = "reserved",
    confirmed = "confirmed",
    mounting = "mounting",
    built = "built",
    indelivery = "indelivery",
    accepted = "accepted",
    unclear = "unclear"
}

export interface fiberSiteAddress{
    country?: string,
    zip?: string,
    city?: string
    village?: string
    street?: string
    hnr?: string //house number
    door?: string
    addon?: string
}


export interface fiberSite {
    sid?: string,
    readonly?: boolean,
    external_id?: string, //max 64 characters
    customer_reference?: string,
    oaid?: string, //6 or 8 digits,
    lot_id?: string,
    token: fiberSiteToken,
    pip: string,
    pop_id?: string,
    popName?: string,
    address?: fiberSiteAddress,
    center: string //WGS84 Latitude,Longitude
    contracts?: Array<string>,
    links?: Array<fiberLink>,
    popNames?: Array<string>,
    pipList?: Array<string>
}
