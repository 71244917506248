import {defineStore, StoreDefinition} from 'pinia'
import axios from 'axios'
import {useAuthStore} from "@/store/authStore";
import {FiberInfo} from "@/models/fiberInfo";

// @ts-ignore
const baseurl = config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL


export type RootState = {
    fiberInfo: FiberInfo,
    token: String,
    config: Object
}

const defaultUserInfo: FiberInfo = {
    version: "1.0.0",
    usage: "development",
    user: {
        username: "Local Test",
        pips: [],
        isps: ["FiberEins", "FiberZwei"],
        contracts: ["fibereins"],
        tenant: "fibereins",
        config: {
            gui: {
                style: "fibereins"
            }
        }
    }
}

// @ts-ignore
export const useFiberInfoStore: StoreDefinition = defineStore({
    id: 'fiberInfo',
    state: () => ({
        fiberInfo: defaultUserInfo,
        token: "",
        config: {}
    } as RootState),

    getters: {
        tenant: (state: any) => {

            if(state.fiberInfo.user.config) {
                return state.fiberInfo.user.config.gui.style
            } else {
                return "fibereins"
            }
        },
        pipNames: (state: any) => {
            return state.fiberInfo.user.pips
        },
        contracts: (state: any) => {
            return state.fiberInfo.user.contracts
        }
    },
    actions: {
        async load() {
            const store = useAuthStore()
            this.token = store.token

            this.config = {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    Accept: "application/json",
                    AccessControlAllowOrigin: "*",
                    ContentType: "application/json"
                }
            };

            return axios.get(baseurl + "/info/", this.config)
                .then((response) => {
                    this.fiberInfo = response.data
                })
        }
    }
})
