import {defineStore} from 'pinia'
import axios from 'axios'
import {dorisSearchResult} from "@/models/dorisSearchResult";
//@ts-ignore
import * as turf from '@turf/turf'
// @ts-ignore
import proj4 from 'proj4'

import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {useFiberSitesStore} from "@/store/fiberSitesStore";
import {useFiberLinksStore} from "@/store/fiberLinksStore";
import {fiberSiteAddress} from "@/models/fiberSite";

// @ts-ignore
const baseurl = "https://srv.doris.at"
const resourceUrl = baseurl + "/solr/searchservice/search/all2/?wt=json2&q="
const pointUrl = baseurl + "/solr/searchservice/search/adressen2/geo/?wt=json&"
//todo: move parameters to request, to have clear sight over all parameters at one place

export type RootState = {
    searchResult: Array<dorisSearchResult>,
    acceptedResult?: dorisSearchResult,
    suggestionsToPosition?: Array<any>
    config: Object
}

export const useDorisSearchStore = defineStore({
    id: 'dorisSearch',
    state: () => ({
        searchResult: [],
        acceptedResult: null,
        suggestionsToPosition: [],
        config: {}
    } as RootState),
    getters: {},
    actions: {
        clearSearch() {
            useFiberLinksStore().filterBy("")
            useFiberPopNodesStore().filterBy("")
            useFiberSitesStore().filterBy("")
            this.searchResult = []
        },
        async findByPosition(position: string) {

            const urlParam = this.buildUrlParam(position)

            await axios.get(pointUrl + urlParam)
                .then((response: any) => {
                    this.suggestionsToPosition = []
                    if (response.data.response.docs && response.data.response.docs.length > 0) {
                        response.data.response.docs.forEach(this.addSuggestion)
                    }
                })

            return this.suggestionsToPosition
        },
        /**
         * position is a wkt point csv
         * @param position
         */
        buildUrlParam(position: string) {
            const flipPosition = position.split(",").reverse().join("+")
            const geoFilter = "{!geofilt+score=distance+sfield=geo+pt=" + position + "+d=0+filter=false}"
            const fq = 'geo:"Intersects(Circle(' + flipPosition + '+d=1000))"'
            const fl = "*,score"
            const sort = "score+asc"

            //the parameters come from the SOLR System: https://cwiki.apache.org/confluence/display/solr/SolrAdaptersForLuceneSpatial4
            //but latest docs are here: https://solr.apache.org/guide/6_6/spatial-search.html
            //parameters are listed here: https://solr.apache.org/guide/6_6/common-query-parameters.html
            /**
             * fq ... filter query: The fq parameter defines a query that can be used to restrict the superset of documents that can be returned, without influencing score.
             * fl ...field list: The fl parameter limits the information included in a query response to a specified list of fields.
             * sort ... The sort parameter arranges search results in either ascending (asc) or descending (desc) order.
             */

            return encodeURI(
                "&q=" + geoFilter
                + "&fq=" + fq
                + "&fl=" + fl
                + "&sort=" + sort
            )
        },
        addSuggestion(docsEntry) {
            const address = {
                street: docsEntry.Strasse[0],
                hnr: docsEntry.Hausnummer[0] + "" + docsEntry.Hausnummernzusatz[0],
                door: "",
                zip: docsEntry.PLZ[0],
                city: docsEntry.Ort[0],
                country: "AT"
            } as fiberSiteAddress

            this.suggestionsToPosition.push(address)
        },
        async findByQuery(query: string) {

            await axios.get(resourceUrl + encodeURI(query))
                .then((response: any) => {
                    this.searchResult = []
                    if (response.data.response.docs && response.data.response.docs.length > 0) {
                        response.data.response.docs.forEach(this.addResult)
                    }
                })
                .finally(() => {
                    const filteredPops = useFiberPopNodesStore().filterBy(query)
                    for (let i = 0; i < filteredPops.length && i < 5; i++) {
                        this.addPop(filteredPops[i])
                    }

                    const filteredSites = useFiberSitesStore().filterBy(query)
                    for (let i = 0; i < filteredSites.length && i < 5; i++) {
                        this.addSite(filteredSites[i])
                    }
                    const filteredLinks = useFiberLinksStore().filterBy(query)
                    for (let i = 0; i < filteredLinks.length && i < 5; i++) {
                        this.addLink(filteredLinks[i])
                    }
                })
        },
        addResult(docsEntry: any): void {

            const searchResult = {
                textSuggestion: docsEntry.textsuggest,
                position: this.convertWktPoint(docsEntry.geo[0]),
                type: "Address",
                resource: docsEntry
            } as dorisSearchResult

            this.searchResult.push(searchResult)
        },
        convertWktPoint(pointFormat) {

            const pointString = pointFormat.replace("POINT(", "").replace(")", "").split(' ')
            const turfPoint = turf.point([parseInt(pointString[0]), parseInt(pointString[1])])
            const res = turf.toWgs84(turfPoint)

            return {
                lat: res.geometry.coordinates[1],
                lng: res.geometry.coordinates[0],
            }
        },
        convertLatLngToPoint(latLng: string): string {
            const parts = latLng.split(",")
            const utmCoordinates = proj4('EPSG:4326', 'EPSG:3857', [parseFloat(parts[1]), parseFloat(parts[0])]);
            return utmCoordinates[1].toFixed(0) + "," + utmCoordinates[0].toFixed(0)
        },
        addPop(popEntry: any) {
            if (!popEntry.center) {
                return
            }

            const searchResult = {
                textSuggestion: popEntry.name,
                position: this.convertPairToLatLng(popEntry.center),
                type: "Pop",
                resource: popEntry
            } as dorisSearchResult

            this.searchResult.push(searchResult)
        },
        addSite(siteEntry: any) {
            if (!siteEntry.center) {
                return
            }

            const searchResult = {
                textSuggestion: this.getAddressString(siteEntry),
                position: this.convertPairToLatLng(siteEntry.center),
                type: "Site",
                resource: siteEntry
            }
            this.searchResult.push(searchResult)
        },
        addLink(linkEntry: any) {

            const searchResult = {
                textSuggestion: "Pop: " + linkEntry.popName + ", Fiber: " + linkEntry.fiber + " - " + linkEntry.token,
                position: null,
                type: "Link",
                resource: linkEntry
            }
            this.searchResult.push(searchResult)
        },
        getAddressString(siteEntry) {
            return siteEntry.address.street + " "
                + siteEntry.address.hnr + " "
                + (siteEntry.address.door != null ? siteEntry.address.door : '')
                + ", " + siteEntry.address.zip
                + " " + siteEntry.address.city
        },
        convertPairToLatLng(pair: string) {
            const pairString = pair.split(',')
            return {
                lat: parseFloat(pairString[0]),
                lng: parseFloat(pairString[1]),
            }
        },
        selectResult(result: dorisSearchResult) {
            this.acceptedResult = result
        }
    }
})
