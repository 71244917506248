
const popColor = (popToken: string) => {
    switch (popToken) {
        case 'planned':
            return "#007bff"
        case 'built':
            return  "hsl(106,91%,23%)"
    }
}

const siteColor = (siteToken: string) => {
    switch (siteToken) {
        case 'reserved':
            return "hsl(0,0%,80%)"
        case 'confirmed':
            return "hsl(60,100%,46%)"
        case 'mounting':
            return "hsl(31,87%,38%)"
        case 'accepted':
            return "hsl(201,93%,57%)"
        case 'indelivery':
            return "hsl(43,89%,55%)"
        case 'unclear':
            return "hsl(0,0%,48%)"
        case 'built':
            return "hsl(106,91%,23%)"
    }
}

const linkLineColor = (linkToken: string) => {
    switch (linkToken) {
        case 'idle':
            return "#444a4f"
        case 'prepared':
            return "#007bff"
        case 'connected':
            return "hsl(106,91%,23%)"
        case 'indelivery':
            return "hsl(43,89%,55%)"
        case 'unclear':
            return "hsl(0,0%,48%)"
    }
}


export {popColor, siteColor, linkLineColor}
