<template>
    <b-button-group class="d-flex flex-fill" size="small">
        <b-button variant="outline-primary" @click="selectFirstPage" title="first" small> &lt;&lt; </b-button>
        <b-button variant="outline-primary" @click="selectPrevPage" title="previous"> &lt; </b-button>
        <b-button variant="outline-primary" disabled> ... </b-button>
        <b-button v-for="(page, index) in pageWindow" :key="index" :variant="variant(page)" @click="selectPage(page)" >
            {{ page }}
        </b-button>
        <b-button variant="outline-primary" disabled> ... </b-button>
        <b-button variant="outline-primary" @click="selectNextPage" title="next"> &gt; </b-button>
        <b-button variant="outline-primary" @click="selectLastPage" :title="'last: '+pages"> &gt;&gt;</b-button>
    </b-button-group>
</template>

<script>
import {defineComponent} from 'vue'
import {BButton, BButtonGroup} from "bootstrap-vue-3";

export default defineComponent({
    name: "paginationBar",
    components: {BButton, BButtonGroup},
    emits: ["selectPage"],
    props: {
        total: {
            type: Number,
            required: true
        },
        pageSize: {
            type: Number,
            required: true
        },
        startingPage: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            currentPage: this.startingPage,
        }
    },
    watch: {
        startingPage(latest, older) {
            if(latest != older) {
                this.currentPage = latest
            }
        }
    },
    computed: {
        pages() {
            return Math.ceil(this.total / this.pageSize)
        },
        pageWindow() {
            let start = this.currentPage - 2
            if(start < 1) {
                start = 1
            }
            let end = this.currentPage + 2
            if(end > this.pages) {
                end = this.pages
            }

            return this.numberRange(start, end+1)
        }
    },
    methods: {
        selectFirstPage() {
            this.currentPage = 1
            this.emitPageChange()
        },
        selectLastPage() {
            this.currentPage = this.pages
            this.emitPageChange()
        },
        selectPrevPage() {
            if(this.currentPage > 1) {
                this.currentPage--
                this.emitPageChange()
            }
        },
        selectNextPage() {
            if(this.currentPage < this.pages) {
                this.currentPage++
                this.emitPageChange()
            }
        },
        selectPage(page) {
            this.currentPage = page
            this.emitPageChange()
        },
        emitPageChange() {
            this.$emit("selectPage", this.currentPage)
        },
        variant(page) {
            if (page === this.currentPage) {
                return "primary"
            }
            return "outline-primary"
        },
        numberRange(start, end) {
            return new Array(end - start).fill().map((d, i) => i + start);
        }
    }
})
</script>


<style scoped>

</style>
