<template>
    <div :class="badgeClass">
        <span class="headline">{{headline}}</span>
        <span class="content">{{mappedContent}}</span>
    </div>
</template>

<script>
export default {
    name: "bootstrapSplitBadge",
    props: {
        variant: String,
        headline: String,
        content: String
    },
    computed: {
        badgeClass() {
            return `myfiber-badge badge-${this.variant} text-nowrap`;
        },
        mappedContent() {
            if (this.content === "indelivery") {
                return "in delivery";
            }
            return this.content;
        }
    }
}
</script>

<style scoped>
.myfiber-badge {
    border: 1px solid #fff;
    border-radius: 0.25rem;
    font-size: 12px;
    line-height: 12px;

    margin: 0;
    padding: 0;
    display: inline-block;
}

.myfiber-badge.badge-primary { /*confirmed*/
    border-color: hsl(60,100%,46%);
}

.myfiber-badge.badge-secondary { /* reserved */
   border-color: hsl(0,0%,80%);
}

.myfiber-badge.badge-success {/*built */
    border-color: hsl(106,91%,23%);
}

.myfiber-badge.badge-danger {
    border-color: #981d08;
}

.myfiber-badge.badge-warning { /* mounting */
    border-color:  hsl(31,87%,38%);
}

.myfiber-badge.badge-info { /* in delivery */
    border-color: hsl(43,89%,55%);
}

.myfiber-badge.badge-light { /* accepted */
    border-color: hsl(201,93%,57%);
}

.myfiber-badge.badge-dark { /* unclear */
    border-color: hsl(0,0%,48%);
}


.headline {
    display: inline-block;
    padding-right: 16px;

    padding-left: 8px;
    line-height: 14px;
}

.myfiber-badge.badge-primary .headline { /* confirmed */
    background-color: hsl(60,100%,46%) ;
    color: #000;
}

.myfiber-badge.badge-secondary .headline { /*reserved*/
    background-color: hsl(0,0%,80%);
    color: #000;
}

.myfiber-badge.badge-success .headline { /*built */
    background-color: hsl(106,91%,23%) ;
    color: #fff;
}

.myfiber-badge.badge-danger .headline {
    background-color: #981d08;
    color: #fff;
}

.myfiber-badge.badge-warning .headline { /* mounting */
    background-color: hsl(31,87%,38%);
    color: #fff;
}

.myfiber-badge.badge-info .headline { /* in delivery */
    background-color: hsl(43,89%,55%);
    color: #000;
}

.myfiber-badge.badge-light .headline { /* accepted */
    background-color: hsl(201,93%,57%);
    color: #000;
}

.myfiber-badge.badge-dark .headline { /* unclear */
    background-color: hsl(0,0%,48%)     ;
    color: #fff;
}

.content{
    color: #000;
    display: inline-block;
    padding-right: 8px;
    padding-left: 16px;
}
</style>
