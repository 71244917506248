<template>
    <div :class="modalClass" tabindex="-1" role="dialog" id="links-modal" :aria-hidden="!isVisible" :style="style">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit / Create Link: <b> OAID: {{ item.oaid }} - {{ pop.name }}</b></h5>
                    <b-button variant="primary" class="close" @click="hideModal()" size="lg">
                        <bootstrap-icon icon="x"></bootstrap-icon>
                    </b-button>
                </div>
                <div class="modal-body">

                    <b-row>
                        <b-col>
                            <b-alert variant="warning" v-model="showError" dismissible @dismissed="errorMessage=''"
                                     class="format-message" v-html="errorMessage"></b-alert>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="12">
                            <b-list-group>
                                <b-list-group-item>
                                    <b-form-group label="Token" label-for="token">
                                        <b-form-select v-model="item.token"
                                                       :options="tokenOptions"
                                                       id="token"></b-form-select>
                                    </b-form-group>
                                    <b-form-group label="Fiber" label-for="fiber">
                                        <b-form-input v-model="item.fiber" type="number" id="fiber"></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="POP ID" label-for="pop-id">
                                        <b-form-select v-model="item.pop_id"
                                                       :options="popList"
                                                       id="pop-id"></b-form-select>
                                    </b-form-group>
                                    <b-form-group label="Contract" label-for="contract">
                                        <b-form-select v-model="item.contract"
                                                       :options="infoStore.contracts"
                                                       id="contract"></b-form-select>
                                    </b-form-group>
                                </b-list-group-item>
                                <b-list-group-item>
                                    <bootstrap-split-badge variant="secondary" headline="OAID"
                                                           :content="item.oaid"></bootstrap-split-badge>
                                    <br>
                                    <bootstrap-split-badge variant="secondary" headline="Pop"
                                                           :content="pop.name"></bootstrap-split-badge>
                                    <br>
                                    <bootstrap-split-badge variant="secondary" headline="External ID"
                                                           :content="item.external_id"></bootstrap-split-badge>
                                    <br>
                                    <bootstrap-split-badge variant="secondary" headline="Customer Reference"
                                                           :content="item.customer_reference"></bootstrap-split-badge>
                                    <br>
                                    <bootstrap-split-badge variant="success" headline="Site ID (SID)"
                                                           :content="item.sid"></bootstrap-split-badge>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                        <b-col md="6" sm="12" v-if="pop_position">
                            <b-list-group>
                                <b-list-group-item>
                                    <b-form-group label="Rack" label-for="rack">
                                        <b-form-input v-model="pop_position.rack" type="text"
                                                      id="rack"></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Shelf" label-for="shelf">
                                        <b-form-input v-model="pop_position.shelf" type="text"
                                                      id="shelf"></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Frame" label-for="frame">
                                        <b-form-input v-model="pop_position.frame" type="text"
                                                      id="frame"></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Patchpanel" label-for="patchpanel">
                                        <b-form-input v-model="pop_position.patchpanel" type="text"
                                                      id="patchpanel"></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Port" label-for="port">
                                        <b-form-input v-model="pop_position.port" type="text"
                                                      id="port"></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Port Type" label-for="port-type">
                                        <b-form-input v-model="pop_position.port_type" type="text"
                                                      id="port-type"></b-form-input>
                                    </b-form-group>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" v-if="newItem" @click="create">Add item</button>
                    <button type="button" class="btn btn-primary" v-else @click="update">Save changes</button>
                    <button type="button" class="btn btn-secondary" @click="hideModal()" data-dismiss="modal">Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import {useFiberLinksStore} from "@/store/fiberLinksStore";
import {useFiberInfoStore} from "@/store/fiberInfoStore";
import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {fiberLinkToken} from "@/models/fiberLink";
import BootstrapSplitBadge from "@/components/atoms/bootstrapSplitBadge.vue";
import {reactive} from "vue";

export default {
    name: "linksRUDModal",
    components: {BootstrapSplitBadge, BootstrapIcon},
    props: {
        modalId: String,
        link: Object,
        popsList: Array
    },
    data() {
        return {
            linksStore: useFiberLinksStore(),
            infoStore: useFiberInfoStore(),
            nodeStore: useFiberPopNodesStore(),
            item: {},
            originalData: reactive({...this.link}),
            pop_position: useFiberLinksStore().getEmptyPopPosition(),
            isVisible: false,
            errorMessage: ""
        }
    },
    updated() {
        this.item = this.link
        this.pop_position = this.link.pop_position
        if (!this.item.pop_id) {
            this.item.pop_id = this.popsList[0].value
        }
    },
    computed: {
        pop() {
            if(this.item.pop_id) {
                const pop = this.nodeStore.getOne(this.item.pop_id)
                return pop
            } else {
                return this.nodeStore.getEmptyPop({lat: 0, lng: 0})
            }
        },
        popList() {
            if(this.popsList) {
                return this.popsList
            }

            return this.nodeStore.popNamesList
        },
        tokenOptions() {
            return Object.values(fiberLinkToken)
        },
        modalClass() {
            return "modal modal-primary modal-xl fade " + (this.isVisible ? "show" : "")
        },
        style() {
            return this.isVisible ? "display: block; background: rgba(0,0,0, 0.6); " : ""
        },
        newItem() {
            return this.item.link_id == null
        },
        showError() {
            return this.errorMessage != ""
        },
    },
    methods: {
        async update() {
            await this.linksStore.update(this.item)
                .then(() => {
                    this.hideModal()
                    this.$emit("send-message", "Updated item", "success", this.item)
                })
                .catch(error => {
                    this.errorMessage = error
                    this.item = this.originalData
                    document.getElementById('links-modal').scrollTo(0, 0);
                })
        },
        async create() {
            await this.linksStore.create(this.item)
                .then((newlyCreatedLinkItem) => {
                    this.hideModal()
                    this.$emit("send-message", "Create item", "success")
                    this.$emit("item-created", newlyCreatedLinkItem)
                })
                .catch(error => {
                    this.errorMessage = error
                    document.getElementById('links-modal').scrollTo(0, 0);
                })
        },
        showModal() {
            this.isVisible = true
        },
        hideModal() {
            this.isVisible = false
        }
    }
}
</script>

<style scoped>
.format-message {
    white-space: pre;
    font-size: 12px;
}

.format-message:first-line {
    font-weight: bold;
    font-size: 14px;
}
</style>
