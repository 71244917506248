<template>
    <p class="text-start m-0" style="white-space: nowrap">
        {{addressString}}
    </p>
</template>

<script>
import {mapAddressShortFormat} from "@/store/util/myFiberDataMapping";

export default {
    name: "address-cell",
    props: {
        address: Object,
        geoPosition: String
    },
    computed: {
        addressString() {
            return mapAddressShortFormat(this.address)
        }
    }
}
</script>

<style scoped>
p{
    min-width: 120px;
    font-size: smaller
}
</style>
