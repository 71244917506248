interface formSelectOption {
    value: string,
    text: string,
    label?: string,
    options?: formSelectOption[]
}

const mapListToFromSelect = function (list: any[], valueField: string, textFields: string[]) {

    const formSelectList: formSelectOption[] = []
    for (const item of list) {

        formSelectList.push({
            value: item[valueField],
            text: concatTextFields(item, textFields)
        })
    }

    return formSelectList
}

const concatTextFields = function (item, textFields) {
    let text = ""
    for (const field of textFields) {
        text += item[field] + " - "
    }

    return text.replace(/\s-\s$/, "")
}

export {mapListToFromSelect}
