<template>
    <div>
        <b-table-simple ref="sitesTable">
            <b-thead>
                <b-tr>
                    <b-th>Token</b-th>
                    <b-th>OAID</b-th>
                </b-tr>
            </b-thead>
            <b-tbody v-if="sitesList.length > 0">
                <template v-for="(site, index) in pagedList" :key="index" >
                    <b-tr @click="onRowSelected(site)" :class="isOdd(index) + ' top'">
                        <b-td><token-badge :token="site.token" reference="site" :small="true" ></token-badge></b-td>
                        <b-td>{{site.oaid}}</b-td>
                    </b-tr>
                    <b-tr @click="onRowSelected(site)" :class="isOdd(index)+ ' bottom'">
                        <b-td colspan="2">
                            <address-cell :address="site.address" :geoPosition="site.center"></address-cell>
                        </b-td>
                    </b-tr>
                </template>
            </b-tbody>
        </b-table-simple>
        <pagination-bar :starting-page="currentPage" :page-size="pageSize" :total="sitesList.length" @select-page="onPageChange"></pagination-bar>
    </div>
</template>

<script>
import AddressCell from "@/components/atoms/address-cell.vue";
import {toast} from "vue3-toastify";
import TokenBadge from "@/components/atoms/tokenBadge.vue";
import {useFiberSitesStore} from "@/store/fiberSitesStore";
import PaginationBar from "@/components/molecules/paginationBar.vue";
import {selectSite} from "@/components/map/utils/storeActions";

export default {
    name: "sitesTable",
    components: {
        PaginationBar,
        TokenBadge,
        AddressCell
    },
    props: {
        pageSize: Number,
        geoPositionForNewItem: Object
    },
    data(){
        return {
            currentSite: null,
            site: {},
            currentPage: 1,
            sitesFields: [
                {
                    key: 'oaid',
                    sortable: false
                },
                {
                    key: 'token',
                    sortable: true
                },
                {
                    key: 'address',
                    sortable: false
                },
            ],
        }
    },
    computed: {
        sitesList() {
            if(useFiberSitesStore().filteredSitesList.length > 0) {
                return useFiberSitesStore().filteredSitesList
            } else {
                return useFiberSitesStore().sitesList
            }
        },
        pagedList() {
            let start = (this.currentPage - 1) * this.pageSize
            return this.sitesList.slice(start, start + this.pageSize)
        },
        selectedSite() {
            return useFiberSitesStore().selectedSite
        }
    },
    watch: {
        sitesList(latest, older) {
            if(latest != older) {
                this.currentPage = 1
            }
        }
    },
    methods: {
        onPageChange(page) {
            this.currentPage = page
        },
        onRowSelected(item) {
            if(item.center == null) {
                toast("No GEO Position found for this site.", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    type: toast.TYPE.ERROR,
                    icon: true,
                    autoClose: 2000,
                })
            }

            selectSite(item)
            this.currentSite = item
        },
        isOdd(index){
            if(index % 2 ==0 ) {
                return "tr-even"
            } else {
                return "tr-odd"
            }
        }
    }
}
</script>

<style scoped>

td{
    font-size: small;
}

td p{
    margin: 0px;
}

tr.tr-odd{
    background: var(--bs-table-striped-bg)
}
tr.top, tr.top td{
    border-bottom: none;
}

tr.bottom, tr.bottom td{
    border-top: none;
}

:deep(.b-table-selection-column){
    display: none !important;
}

#add-button, #total-display {
    height: 31px !important;
}

.btn-outline-primary.no-hover:hover {
    cursor:default !important;
    --bs-btn-hover-color:  #0d6efd;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #0d6efd;
}

#overlay {
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(65, 65, 65, 0.7);
    width: 100%;
    height: 100%;

    cursor: not-allowed;

    padding-top: 500px;
    padding-left: 120px;
    padding-right: 120px;

    z-index: 3;
}

</style>
