
import {defineComponent} from 'vue'
import {fiberSite} from "@/models/fiberSite";
import SiteMarkerPopup from "@/components/map/children/siteMarkerPopup.vue";
import {LPopup} from "@vue-leaflet/vue-leaflet";

export default defineComponent({
    name: "SitePopup",
    components: {LPopup, SiteMarkerPopup},
    props: {
        site: {
            type: Object as () => fiberSite
        }
    }
})
