import {defineStore} from 'pinia'
import axios from 'axios'
import {useAuthStore} from "@/store/authStore";

import {fiberPip} from "@/models/fiberPip";

// @ts-ignore
const baseurl = config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL
const resourceUrl = baseurl + "/mgm/pips/"

export type RootState = {
    pipsList: fiberPip[],
    token: String,
    config: Object
}
export const useFiberPipsStore = defineStore({
    id: 'fiberPips',
    state: () => ({
        pipsList: [],
        token: "",
        config: {}
    } as RootState),
    getters: { },
    actions: {
        load() {
            const store = useAuthStore()
            this.token = store.token

            this.config = {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    Accept: "application/json",
                    AccessControlAllowOrigin: "*",
                    ContentType: "application/json"
                }
            };

            axios.get(resourceUrl, this.config)
                .then((response) => {
                    response.data.forEach(this.addItem)
                })
        },
        addItem(item: fiberPip) {
            item.readonly = !item.api_settings?.edit_allowed //i.e. edit_allowed = false => readonly is true
            this.pipsList.push(item)
        },
    }
})
