<script>
import {defineComponent} from 'vue'
import {LLayerGroup, LPopup} from "@vue-leaflet/vue-leaflet";
import BootstrapIcon from "@dvuckovic/vue3-bootstrap-icons"

export default defineComponent({
    name: "contextMenu",
    components: {LLayerGroup, LPopup, BootstrapIcon},
    emits: ["zoom-in"],
    data () {
        return {
            latLng: null,
            options: {offset: [0, 0]}
        }
    },
    methods: {
        open(latLng) {
            this.latLng = latLng
            this.$refs['context'].leafletObject.openPopup(latLng)
        },
        zoomIn() {
            this.$refs['context'].leafletObject.closePopup()
            this.$emit('zoom-in', this.latLng)
        }
    }
})
</script>

<template>
    <l-layer-group ref="context">
        <l-popup :options="options" :close-button="false">
            <b-button @click="zoomIn" variant="primary">
                <bootstrap-icon icon="zoom-in"></bootstrap-icon>
                Zoom here
            </b-button>
        </l-popup>
    </l-layer-group>

</template>

<style scoped>
.btn {
    margin: -7px -19px -7px -15px;
}

.leaflet-popup-tip{
    display: none !important;
}
</style>
