<template>
    <div>
        <b-table-simple ref="linksTable" responsive>
            <b-thead>
                <b-tr>
                    <b-th>Token</b-th>
                    <b-th>OAID</b-th>
                    <b-th title="Fiber">F.</b-th>
                    <b-th>Pop Name</b-th>
                    <!--b-th>Contract</b-th>
                    <b-th>Pip</b-th-->
                </b-tr>
            </b-thead>
            <b-tbody v-if="linksList.length > 0">
                <template v-for="(link, index) in pagedList" :key="index">
                    <b-tr @click="onRowSelected(link)" :class="isOdd(index)">
                        <b-td><token-badge :small="true" :token="link.token" reference="link"></token-badge></b-td>
                        <b-td>{{link.oaid}}</b-td>
                        <b-td>{{link.fiber}}</b-td>
                        <b-td>{{link.popName}}</b-td>
                        <!--b-td>{{link.contract}}</b-td>
                        <b-td class="text-nowrap">{{link.pip}}</b-td-->
                    </b-tr>
                </template>
            </b-tbody>
        </b-table-simple>
        <pagination-bar :starting-page="currentPage" :page-size="pageSize" :total="linksList.length" @select-page="onPageChange"></pagination-bar>
    </div>
</template>

<script>
import TokenBadge from "@/components/atoms/tokenBadge.vue";
import PaginationBar from "@/components/molecules/paginationBar.vue";

export default {
    name: "linksTable",
    components: {PaginationBar, TokenBadge},
    props: {
        fiberLinksStore: Object,
        pageSize: Number,
        selectedLink: Object,
    },
    data() {
        return {
            currentLink: null,
            link: {},
            currentPage: 1,
            linksFields: [
                {
                    key: 'token',
                    sortable: true
                },
                {
                    key: 'fiber',
                    sortable: true
                },
                {
                    key: 'contract',
                    sortable: true
                },
                {
                    key: 'pip',
                    sortable: true
                },
                {
                    key: 'popName',
                    sortable: true
                },
                {
                    key: 'oaid',
                    sortable: false
                }
            ],
        }
    },
    computed: {
        totalRows() {
            return this.linksList.length
        },
        linksList() {
            if( this.fiberLinksStore.filteredLinksList.length > 0) {
                return this.fiberLinksStore.filteredLinksList
            } else {
                return this.fiberLinksStore.linksList
            }
        },
        pagedList() {
            let start = (this.currentPage - 1) * this.pageSize
            return this.linksList.slice(start, start + this.pageSize)
        }

    },
    watch: {
        linksList(latest, older){
            if(latest != older) {
                this.currentPage = 1
            }
        }
    },
    methods: {
        onPageChange(page) {
            this.currentPage = page
        },
        onRowSelected(item) {
            this.$emit("link-selected", item)
        },
        isOdd(index){
            if(index % 2 ==0 ) {
                return "tr-even"
            } else {
                return "tr-odd"
            }
        },
    }
}
</script>

<style scoped>

td{
    font-size: small;
}

tr.tr-odd{
    background: var(--bs-table-striped-bg)
}

</style>
