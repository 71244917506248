import { createRouter, createWebHistory } from 'vue-router'
import administration from "@/components/administration.vue";
import importModal from "@/components/siteImporter/importModal.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: administration
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
