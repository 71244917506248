const handleMyFiberErrorMessage = function (error) {
    let errorMessage = error //maybe errors from within the state object
    if (error.response) { //errors from api

        errorMessage = error.response.data
        if (error.response.data.message) {
            errorMessage = error.response.data.message
        }
        if (error.response.data.hint) {
            errorMessage += "<br><b>Hint</b><br>" + error.response.data.hint
        }
    }

    return errorMessage
}

export {handleMyFiberErrorMessage}
