
import {defineComponent} from 'vue'
import BootstrapSplitBadge from "@/components/atoms/bootstrapSplitBadge.vue";
import {mapTokenToVariant} from "@/store/util/myFiberDataMapping";
import {fiberLink} from "@/models/fiberLink";

export default defineComponent({
    name: "linkEntryView",
    methods: {mapTokenToVariant},
    props: {
        link: {
            type: Object as () => fiberLink
        }
    },
    components: {BootstrapSplitBadge}
})
