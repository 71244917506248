import xlsx from 'xlsx';
import {useFiberSitesStore} from "@/store/fiberSitesStore";
import {mapFiberSiteExcelDTO} from "@/DTOs/fiberSiteExcelDTO";
import {mapFiberPopExcelDTO} from "@/DTOs/fiberPopExcelDTO";
import {mapFiberLinkExcelDTO} from "@/DTOs/fiberLinksExcelDTO";
import {useFiberLinksStore} from "@/store/fiberLinksStore";
import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {fiberPopNode} from "@/models/fiberPopNode";
import {fiberLink} from "@/models/fiberLink";

const exportSitesExcel = function() {
    const sites = useFiberSitesStore().filteredSitesList
    const workSheet = getSitesSheet(sites)
    const workbook = getWorkbook()
    xlsx.utils.book_append_sheet(workbook, workSheet, "Sites")

    const today = new Date()
    const filename = today.toISOString().substring(0,10) + "_MyFiber_Sites.xlsx"

    startDownload(workbook, filename)
}

const exportPopsExcel = function() {

    const pops = getAvailablePopNodes()

    const workSheet = getPopsSheet(pops)
    const workbook = getWorkbook()
    xlsx.utils.book_append_sheet(workbook, workSheet, "Pops")

    const today = new Date()
    const filename = today.toISOString().substring(0,10) + "_MyFiber_POPs.xlsx"

    startDownload(workbook, filename)
}

const getAvailablePopNodes = function(): fiberPopNode[] {

    let popList:fiberPopNode[] = useFiberPopNodesStore().filteredNodesList

    if(popList.length === 0) {


        popList = []
        const sites = useFiberSitesStore().filteredSitesList
        for (const site of sites) {
            const pop = useFiberPopNodesStore().find(site.pop_id)
            if (pop != null && !popList.includes(pop)) {
                popList.push(pop)
            }
        }
    }

    return popList
}

const exportLinksExcel = function() {

    const links = getAvailableLinks()

    const workSheet = getLinksSheet(links)
    const workbook = getWorkbook()
    xlsx.utils.book_append_sheet(workbook, workSheet, "Links")

    const today = new Date()
    const filename = today.toISOString().substring(0,10) + "_MyFiber_Sites.xlsx"

    startDownload(workbook, filename)
}

const getAvailableLinks = function(): fiberLink[] {

    let linksList: fiberLink[] = useFiberLinksStore().filteredLinksList

    if(linksList.length == 0) {

        linksList = []
        const sites = useFiberSitesStore().filteredSitesList
        for (const site of sites) {
            const links = site.links
            linksList = linksList.concat(links)
        }
    }

    return linksList

}

const exportResourcesExcel = function() {
    const sites = useFiberSitesStore().filteredSitesList
    const workSheetSites = getSitesSheet(sites)

    const pops = getAvailablePopNodes()
    const workSheetPops = getPopsSheet(pops)

    const links = getAvailableLinks()
    const workSheetLinks = getLinksSheet(links)

    const workbook = getWorkbook()
    xlsx.utils.book_append_sheet(workbook, workSheetSites, "Sites")
    xlsx.utils.book_append_sheet(workbook, workSheetPops, "POPs")
    xlsx.utils.book_append_sheet(workbook, workSheetLinks, "Links")

    const today = new Date()
    const filename = today.toISOString().substring(0,10) + "_MyFiber_Resources.xlsx"

    startDownload(workbook, filename)
}

const exportAllResoucrcesExcel = function() {
    useFiberSitesStore().fillFilteredList()
    exportResourcesExcel()
}


export {exportSitesExcel, exportPopsExcel, exportLinksExcel, exportResourcesExcel, exportAllResoucrcesExcel}


function getWorkbook() {
    return xlsx.utils.book_new();
}
function getSitesSheet(sitesList){
    const table = []

    for(const site of sitesList) {
        table.push(mapFiberSiteExcelDTO(site))
    }

    return xlsx.utils.json_to_sheet(table);
}

function getPopsSheet(popsList){
    const table = []

    for(const pop of popsList) {
        table.push(mapFiberPopExcelDTO(pop))
    }

    return xlsx.utils.json_to_sheet(table);
}

function getLinksSheet(linksList) {
    const table = []

    for(const link of linksList) {
        table.push(mapFiberLinkExcelDTO(link))
    }

    return xlsx.utils.json_to_sheet(table);
}

function startDownload(workbook, filename){
    xlsx.writeFile(workbook, filename, { compression: true });
}
