<template>
    <span>
        <b-badge class="state-token" :variant="variant" :id="'popover-badge-target-'+badgeId">
            <bootstrap-icon :icon="icon"></bootstrap-icon>
            <template v-if="!small"> {{ tokenName }}</template>
        </b-badge>
        <template v-if="small">
            <b-popover :target="'popover-badge-target-'+badgeId" triggers="hover" placement="right">
                <bootstrap-icon :icon="icon"></bootstrap-icon> {{ tokenName }}
            </b-popover>
        </template>
    </span>

</template>

<script>
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import {BBadge} from "bootstrap-vue-3"
import {v4 as uuidv4} from 'uuid';
import {mapTokenToIcon, mapTokenToVariant} from "@/store/util/myFiberDataMapping";

export default {
    name: "tokenBadge",
    components: {BootstrapIcon, BBadge},
    props: {
        token: String,
        reference: String,
        small: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        badgeId() {
            return uuidv4()
        },
        variant() {
            return mapTokenToVariant(this.token)
        },
        icon() {
            return mapTokenToIcon(this.token)
        },
        tokenName() {
            if (this.token === "indelivery") {
                return "in delivery";
            }
            return this.token;
        }
    }
}
</script>

<style scoped>

</style>
