import {defineStore} from 'pinia'
import axios from 'axios'
import {useAuthStore} from "@/store/authStore";
import {fiberLink, fiberLinkToken, PopPosition} from "@/models/fiberLink";
import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {handleMyFiberErrorMessage} from "@/store/util/myFiberErrorMessageHandler";

// @ts-ignore
const baseurl = config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL


export type RootState = {
    linksList: fiberLink[],
    filteredLinksList: fiberLink[],
    token: String,
    config: Object
}

export const useFiberLinksStore = defineStore({
    id: 'fiberLinks',
    state: () => ({
        linksList: [],
        filteredLinksList: [],
        token: "",
        config: {}
    } as RootState),

    getters: {
        getBySID(state): any {
            return (sid: any) => {
                const linkList: any[] = []
                for (const link of state.linksList) {

                    if (link.sid == sid) {
                        linkList.push(link)
                    }
                }

                return linkList
            }
        },
        filterBy(state): any {
            return (filter: string) => {
                if (filter == "") {
                    this.filteredLinksList = this.linksList
                    return this.linksList
                }

                const filteredLinksList: any[] = []
                for (const link of state.linksList) {

                    const jsonString = JSON.stringify(link)
                    const regEx = new RegExp(filter, "i")

                    if (jsonString.search(regEx) > -1) {
                        filteredLinksList.push(link)
                    }

                }
                this.filteredLinksList = filteredLinksList
                return filteredLinksList
            }
        },
        getWherePopId(state): any {
            return (pop_id: string) => {
                const linkList: any[] = []
                for (const link of state.linksList) {

                    if (link.pop_id == pop_id) {
                        linkList.push(link)
                    }
                }

                return linkList
            }
        }
    },
    actions: {
        authenticate() {
            const store = useAuthStore()
            this.token = store.token

            this.config = {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    Accept: "application/json",
                    AccessControlAllowOrigin: "*",
                    ContentType: "application/json"
                }
            };
        },
        async load(page?: number) {

            const store = useAuthStore()
            this.token = store.token

            this.config = {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    Accept: "application/json",
                    AccessControlAllowOrigin: "*",
                    ContentType: "application/json"
                }
            };

            if (!page) {
                this.linksList = []
                page = 0
            }
            page++
            const url: string = baseurl + "/resource/links/" + "?_size=500" + "&_page=" + (page)

            //const url: string = './test-data/export-test-no-pop-links.json'
            await axios.get(url, this.config)
                .then(async (response) => {
                    response.data.forEach(this.addItem)

                    // @ts-ignore
                    if (response.data.length > 0) {
                        await useFiberLinksStore().load(page)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })

            return this.linksList
        },
        addItem(linkItem: fiberLink) {

            if (linkItem.pop_position == null) {
                linkItem.pop_position = ({
                    rack: "",
                    frame: "",
                    patchpanel: "",
                    port: "",
                    port_type: "",
                    shelf: ""
                } as PopPosition)
            } else {
                if(linkItem.pop_position.rack === null) {
                    linkItem.pop_position.rack = ""
                }
                if(linkItem.pop_position.frame === null) {
                    linkItem.pop_position.frame = ""
                }
                if(linkItem.pop_position.patchpanel === null) {
                    linkItem.pop_position.patchpanel = ""
                }
                if(linkItem.pop_position.port === null) {
                    linkItem.pop_position.port = ""
                }
                if(linkItem.pop_position.port_type === null) {
                    linkItem.pop_position.port_type = ""
                }
                if(linkItem.pop_position.shelf === null) {
                    linkItem.pop_position.shelf = ""
                }

            }



            linkItem.popName = useFiberPopNodesStore().getPopNameByID(linkItem.pop_id)
            this.linksList.push(linkItem as fiberLink)
            return linkItem
        },

        getOne(id: string): any {
            for (const item of this.linksList) {
                if (item.link_id == id) {
                    return item;
                }
            }

            throw new Error("Link with id " + id + " not found.")
        },

        findIndexById(id: string): number | boolean {

            for (let i = 0; i < this.linksList.length; i++) {
                const item = this.linksList[i]

                if (item.link_id == id) {
                    return i
                }
            }

            throw new Error("Item not in List anymore.")
        },
        getEmptyPopPosition() {
            return {
                rack: "",
                port: "",
                port_type: "",
                frame: "",
                patchpanel: "",
                shelf: ""
            } as PopPosition
        },
        getEmptyLink():fiberLink {
            return {
                link_id: null,
                sid: null,
                status: "active",
                token: fiberLinkToken.prepared,
                fiber: null,
                pop_position: this.getEmptyPopPosition(),
                pop_id: null,
                contract: "",
                external_id: null,
            }
        },
        create(linkItem: fiberLink) {
            if (!linkItem.status) {
                linkItem.status = "active"
            }
            this.authenticate()
            const linkDto = this.mapDTO(linkItem)
            return axios.post(baseurl + "/resource/links/", linkDto, this.config)
                .then((response) => this.addItem(response.data))
                .catch((error) => {
                    throw new Error("Item could not be created.<br>"
                        + handleMyFiberErrorMessage(error))
                })

        },
        update(linkItem: fiberLink) {

            const linkDto = this.mapDTO(linkItem)

            return axios.patch(baseurl + "/resource/links/" + linkItem.link_id + "/", linkDto, this.config)
                .then(() => {
                    let index
                    if (linkItem.link_id != null) {
                        index = this.findIndexById(linkItem.link_id)
                        this.linksList[index] = linkItem
                    }
                })
                .catch((error) => {
                    throw new Error("Item could not be updated.<br>"
                        + handleMyFiberErrorMessage(error))
                })
        },
        remove(linkItem: fiberLink) {
            return axios.delete(baseurl + "/resource/links/" + linkItem.link_id + "/", this.config)
                .then(() => {
                    if (linkItem.link_id != null) {
                        const index = this.findIndexById(linkItem.link_id)
                        this.linksList.splice(index, 1)
                    }
                })
                .catch((error) => {
                    throw new Error("Item could not be deleted.<br>"
                        + handleMyFiberErrorMessage(error))
                })
        },
        mapDTO(linkItem: fiberLink): fiberLink {

            const fiberLinkDTO = ({
                sid: linkItem.sid,
                fiber: linkItem.fiber,
                status: linkItem.status,
                token: linkItem.token,
                contract: linkItem.contract,
                pop_id: linkItem.pop_id,
                pop_position: linkItem.pop_position,
                external_id: linkItem.external_id
            } as fiberLink)

            return fiberLinkDTO
        },
    }
})


