export enum fiberLinkToken {
    idle = "idle",
    prepared = "prepared",
    connected = "connected",
    indelivery = "indelivery",
    unclear = "unclear"
}

export interface PopPosition {
    rack?: string,
    shelf?: string,
    frame?: string,
    patchpanel?: string,
    port?: string,
    port_type?: string,
}

export interface fiberLink {
    link_id?: string,
    sid: string, //Site Id
    fiber: string, //fiberNumber
    status: string,
    token: fiberLinkToken,
    oaid?: string, //open access ID
    contract: string,
    pip?: string,
    pop_id?: string,
    popName?: string,
    pop_position?: PopPosition,
    external_id?: string
}
