<template>
    <template v-if="useInteractionStore().resetMarkerAction">
        <b-navbar toggleable="lg" type="dark" variant="primary" id="header-nav-bar" class="border-bottom">
            <span class="align-content-center text-white">Reset Marker - {{siteTitle}}</span>
            <b-button variant="primary" class="close" @click="useInteractionStore().deactivateMarkerAction()" >
                <bootstrap-icon icon="x"></bootstrap-icon>
            </b-button>
        </b-navbar>
    </template>
    <template v-else>
        <b-navbar toggleable="lg" type="dark" variant="default" id="header-nav-bar" class="border-bottom">
            <b-navbar-brand  href="#"><img :src="logoPath" id="header-logo" /></b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <search-bar></search-bar>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item :href="keycloakLogoutUrl">Logout</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </template>
</template>

<script>
import keycloakConfig from '../../public/keycloak.json'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import SearchBar from "@/components/searchbar/searchBar.vue";
import {useInteractionStore} from "@/store/interactionStore";
import {useFiberSitesStore} from "@/store/fiberSitesStore";
import {mapAddressShortFormat} from "@/store/util/myFiberDataMapping";
export default {
    name: "theHeader",
    methods: {useInteractionStore},
    components: {SearchBar, BootstrapIcon},
    props: {
        tenant: String
    },
    computed: {
        siteTitle() {
            return mapAddressShortFormat(useFiberSitesStore().selectedSite.address)
        },
        logoPath() {
            switch (this.tenant) {
                case "kelag":
                    return "/images/kelag-logo.png"
                case "bbooe":
                    return "/images/bbooe-logo.png"
                case "laab":
                    return "/images/laab-logo.png"
                case "noegig":
                    return "/images/noegig-logo.png"
                case "fiberEins":
                default:
                    return "/images/logo.png"
            }
        },
        keycloakLogoutUrl() {
            const redirectUrl = window.location
            return keycloakConfig['auth-server-url'] + "/realms/" + keycloakConfig.realm + "/protocol/openid-connect/logout?redirect_uri=" + redirectUrl
        }
    }
}
</script>

<style scoped>

#header-logo {
    width: 68px;
    height: auto;
}

.navbar-brand{
    padding: 0px;
}

</style>
