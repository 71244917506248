<template>
    <b-container fluid id="main-container">
        <b-row class="mb-2">
            <b-col cols="12" sm="12" md="4" lg="3" x-lg="2" class="p-0 pe-1">
                <b-card title="" header-tag="nav" id="table-card">

                    <node-detail-view ref="nodeDetailView" :geoPositionForNewItem="lastClickedPosition" v-if="selectedPop"></node-detail-view>
                    <site-detail-view ref="siteDetailView" :geoPositionForNewItem="lastClickedPosition" v-if="selectedSite"></site-detail-view>
                    <link-detail-view :link="selectedLink" v-if="selectedLink"></link-detail-view>

                    <b-tabs content-class="mt-1" justified active-nav-item-class="bg-primary text-white"
                            v-model="tabIndex" small id="resource-tabs">
                        <b-tab class="small" :title="sitesTitle">
                            <sites-table :page-size="10" ref="sitesTable"></sites-table>
                        </b-tab>
                        <b-tab :title="linksTitle">
                            <links-table @link-selected="selectLink" ref="linksTable" :selectedLink="selectedLink"
                                         :fiber-links-store="fiberLinksStore" :page-size="15"></links-table>
                        </b-tab>
                        <b-tab :title="popsTitle">
                            <nodes-table :fiber-node-store="fiberNodeStore" :page-size="15" ref="popsTable"></nodes-table>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
            <b-col class="p-0">
                <map-view :wmts-url="wmtsUrl"
                          ref="bigMap"
                          :layers="[(wmtsLayerName+' Grau'), (wmtsLayerName+' Orthofoto'), wmtsLayerName]"
                          :popMarker="fiberNodeStore.nodesList"
                          :siteMarker="fiberSitesStore.displayedSitesList"
                          @clicked-map-at="savePosition"
                          @add-site="tryToAddSite"
                          @add-pop="tryToAddPop"
                          @update:bounding-box="loadSites"
                ></map-view>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {useFiberLinksStore} from "@/store/fiberLinksStore";
import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import SitesTable from "@/components/resources/sites/sitesTable.vue";
import {useFiberSitesStore} from "@/store/fiberSitesStore";
import LinksTable from "@/components/resources/links/linksTable.vue";
import NodesTable from "@/components/resources/nodes/nodesTable.vue";
import MapView from "@/components/map/mapView.vue";
import {useFiberPipsStore} from "@/store/fiberPipsStore";
import NodeDetailView from "@/components/resources/nodes/nodeDetailView.vue";
import SiteDetailView from "@/components/resources/sites/siteDetailView.vue";
import {useDorisSearchStore} from "@/store/dorisSearchStore";
import LinkDetailView from "@/components/resources/links/linkDetailView.vue";
import {useInteractionStore} from "@/store/interactionStore";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "administration",
    components: {LinkDetailView, SiteDetailView, NodeDetailView, MapView, NodesTable, LinksTable, SitesTable},
    async setup() {
        await useFiberPipsStore().load()
        await useFiberPopNodesStore().load()
        await useFiberLinksStore().load()
        useFiberSitesStore().loadAll()
    },
    data: () => {
        return {
            wmtsLayerName: "Geoland Basemap",
            //wmtsUrl: "/assets/basemap/1.0.0/WMTSCapabilities.xml",
            wmtsUrl: "https://maps.wien.gv.at/basemap/1.0.0/WMTSCapabilities.xml",
            fiberPipStore: useFiberPipsStore(),
            fiberSitesStore: useFiberSitesStore(),
            fiberLinksStore: useFiberLinksStore(),
            fiberNodeStore: useFiberPopNodesStore(),
            lastClickedPosition: [],
            displayMap: true,
            position: useInteractionStore().mapPosition,
            selectedLink: null,
            tabIndex: 0
        }
    },
    computed: {
        selectedPop() {
            return this.fiberNodeStore.selectedNode
        },
        selectedSite() {
            return this.fiberSitesStore.selectedSite
        },
        siteMarkerList() {
            return this.fiberSitesStore.displayedSitesList
        },
        acceptedSearchResult() {
            return useDorisSearchStore().acceptedResult
        },
        sitesTitle() {
            let filtered = this.fiberSitesStore.filteredSitesList.length
            if(this.fiberSitesStore.filteredSitesList.length == 0) {
                filtered = this.fiberSitesStore.sitesList.length
            }

            return 'Sites ('+filtered+'/' + this.fiberSitesStore.sitesList.length + ')'
        },
        linksTitle() {
            let filtered = this.fiberLinksStore.filteredLinksList.length
            if(this.fiberLinksStore.filteredLinksList.length == 0) {
                filtered = this.fiberLinksStore.linksList.length
            }

            return 'Links ('+filtered+'/' + this.fiberLinksStore.linksList.length + ')'
        },
        popsTitle() {
            let filtered = this.fiberNodeStore.filteredNodesList.length
            if(this.fiberNodeStore.filteredNodesList.length == 0) {
                filtered = this.fiberNodeStore.nodesList.length
            }

            return 'POPs ('+filtered+'/' + this.fiberNodeStore.nodesList.length + ')'
        }
    },
    watch: {
        acceptedSearchResult(latest, older) {
            if (latest && latest != older) {
                let zoomLevel = 12
                switch (latest.type) {
                    case "Site":
                        this.selectSite(latest.resource)
                        break
                    case "Pop":
                        this.selectPop(latest.resource)
                        break
                    case "Link":
                        this.selectLink(latest.resource)
                        break
                    default:
                        this.$refs['bigMap'].zoomMap(latest.position, zoomLevel);
                        break
                }
            }
        }
    },
    methods: {
        savePosition(geoPosition) {
            this.lastClickedPosition = geoPosition
        },
        selectPop(pop) {
            useFiberPopNodesStore().setSelectedNode(pop)
            useFiberSitesStore().unsetSelectedSite()
            this.selectedLink = null
        },
        selectSite(site) {
            useFiberSitesStore().setSelectedSite(site)
            useFiberPopNodesStore().unsetSelectedNode()
            this.selectedLink = null

        },
        selectLink(link) {
            useFiberSitesStore().unsetSelectedSite()
            useFiberPopNodesStore().unsetSelectedNode()
            this.selectedLink = link
        },
        tryToAddSite(latlng) {
            this.tabIndex = 0

            this.$nextTick(() => {
                this.fiberSitesStore.setSelectedSite(this.fiberSitesStore.getEmptySite(latlng))
                this.$nextTick(() => {this.$refs['siteDetailView'].showModal()})
                this.lastClickedPosition = latlng
            })
        },
        tryToAddPop(latlng) {
            this.tabIndex = 2

            this.$nextTick(() => {
                this.fiberNodeStore.setSelectedNode(this.fiberNodeStore.getEmptyPop(latlng))
                this.$nextTick(() => {this.$refs['nodeDetailView'].showModal()})
                this.lastClickedPosition = latlng
            })
        },
        loadSites(boundingBox) {
            this.fiberSitesStore.loadInBoundingBox(boundingBox)
        }
    }
}
</script>

<style scoped>
#main-container {
    padding-top: 8px;
}

#table-card {
    height: calc(100vh - 71px);
    overflow-x: hidden;
    overflow-y: auto;
}

:deep(#table-card > .card-body ) {
    padding: 1px !important;
}

:deep(#resource-tabs button) {
    font-size: 12px !important;
}

@media (max-width: 576px) {
    #table-card {
        height: auto;
        overflow: auto;
    }

    :deep(#table-card > .card-body ) {
        padding: 1px !important;
    }

    #main-container {
        width: 100%;
        height: auto;
    }

}

</style>
