import {fiberPopNode} from "@/models/fiberPopNode";
import {mapAddressShortFormat} from "@/store/util/myFiberDataMapping";

const mapFiberPopExcelDTO = function (pop: fiberPopNode) {
    return {
        token: pop.token,
        name: pop.name,
        pip: pop.pip,
        center: pop.center,
        address: mapAddressShortFormat(pop.address),
        customer_reference: pop.customer_reference,
        external_id: pop.external_id
    }
}

export {mapFiberPopExcelDTO}
