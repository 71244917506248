import {defineStore} from "pinia";
import {extractLatLngAsString, roundLatLng} from "@/store/util/myFiberDataMapping";

export const useInteractionStore = defineStore({
    id: 'interactionStore',
    state: () => ({
        resetMarkerAction: false,
        newMarkerPosition: null,
        positionConfirmed: false,
        mapPosition: {
            center: [46.765529, 13.886718],
            zoom: 9
        }
    }),
    actions: {
        loadMapPosition() {
            if(localStorage.getItem('mapPosition')) {
                this.mapPosition = JSON.parse(localStorage.getItem('mapPosition'))
            }
        },
        storeMapPosition() {
            localStorage.setItem('mapPosition', JSON.stringify(this.mapPosition))
        },
        setMapPosition(center, zoomLevel) {
            this.mapPosition.center = center
            this.mapPosition.zoom = zoomLevel
            this.storeMapPosition()
        },
        activateMarkerAction() {
            this.resetMarkerAction = true
        },
        deactivateMarkerAction() {
            this.resetMarkerAction = false

        },
        setNewMarkerPosition(latlng) {
            const geoPosition = roundLatLng(latlng)
            const latlngString = extractLatLngAsString(geoPosition)

            this.newMarkerPosition = latlngString
        },
        unsetNewMarkerPosition() {
            this.newMarkerPosition = null
        },
        setPositionConfirmed(confirmed: boolean = true) {
            this.positionConfirmed = confirmed
        }
    }
})
