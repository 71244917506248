import {fiberSite} from "@/models/fiberSite";
import {
    extractContractsFromLinks, extractPipsFromLinks,
    extractPopNamesFromLinks,
    mapAddressShortFormat
} from "@/store/util/myFiberDataMapping";

const mapFiberSiteExcelDTO = function (siteItem: fiberSite): any {
    return {

        token: siteItem.token,
        oaid: siteItem.oaid,
        address: mapAddressShortFormat(siteItem.address),
        pops: extractPopNamesFromLinks(siteItem.links).join("\n"),
        contracts: extractContractsFromLinks(siteItem.links).join("\n"),
        pip: extractPipsFromLinks(siteItem.links).join("\n"),
        external_id: siteItem.external_id,
        customer_reference: siteItem.customer_reference,
        lot_id: siteItem.lot_id,
        center: siteItem.center
    }
}

export {mapFiberSiteExcelDTO}
