import {fiberSite} from "@/models/fiberSite";
import {
    extractContractsFromLinks, extractPipsFromLinks,
    extractPopNamesFromLinks,
    mapAddressShortFormat
} from "@/store/util/myFiberDataMapping";

const mapFiberSiteDTO = function (siteItem: fiberSite): fiberSite {
    const fiberSiteDTO = ({
        external_id: siteItem.external_id,
        customer_reference: siteItem.customer_reference,
        token: siteItem.token,
        pip: siteItem.pip,
        pop_id: siteItem.pop_id,
        address: siteItem.address,
        lot_id: siteItem.lot_id,
        center: siteItem.center
    } as fiberSite)

    return fiberSiteDTO
}

export {mapFiberSiteDTO}
