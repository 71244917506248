const extractDataFromServiceTemplate = function (data: any, layerName: any) {

    const parser = new DOMParser()
    const GetCapabilitiesXML = parser.parseFromString(data, 'text/xml');

    const layerNodes = GetCapabilitiesXML.getElementsByTagName("Layer");

    for (let i = 0; i < layerNodes.length; i++) {
        const title = layerNodes[i].getElementsByTagName("ows:Title")[0].childNodes[0].nodeValue;

        if (title == layerName) {
            const style = layerNodes[i].getElementsByTagName("Style")[0].children[0].childNodes[0].nodeValue;
            const tileMatrixSet = layerNodes[i].getElementsByTagName("TileMatrixSetLink")[0].children[0].childNodes[0].nodeValue;
            //@ts-ignore
            const serviceURLTemplates = layerNodes[i].getElementsByTagName("ResourceURL")[4].attributes.getNamedItem("template").nodeValue;

            return createLeafletLayerUrl(serviceURLTemplates, tileMatrixSet, style);
        }
    }
}


// callback function: create Leaflet tileLayer when ready and add to map
const createLeafletLayerUrl = function (serviceURLTemplates: any, tileMatrixSet: any, style: any) {

    const baseURL = serviceURLTemplates.replace("maps", "{s}")
        .replace("{Style}", style)
        .replace("{TileMatrixSet}", tileMatrixSet)
        .replace("{TileMatrix}", "{z}")
        .replace("{TileRow}", "{y}")
        .replace("{TileCol}", "{x}");

    return baseURL
}

function getCenter(centerString: string): string[] {
    if (centerString) {
        //console.log(centerString)
        return centerString.split(",")
    } else {
        return ["0", "0"]
    }
}

export {extractDataFromServiceTemplate, getCenter}
