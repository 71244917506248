import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bootstrap_split_badge = _resolveComponent("bootstrap-split-badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bootstrap_split_badge, {
      class: "mx-1",
      variant: _ctx.mapTokenToVariant(_ctx.link.token),
      headline: 'Fiber '+_ctx.link.fiber,
      content: _ctx.link.token
    }, null, 8, ["variant", "headline", "content"]),
    _createVNode(_component_bootstrap_split_badge, {
      class: "mx-1",
      variant: "secondary",
      headline: "Pop",
      content: _ctx.link.popName
    }, null, 8, ["content"]),
    _createVNode(_component_bootstrap_split_badge, {
      class: "mx-1",
      variant: "secondary",
      headline: "contract",
      content: _ctx.link.contract
    }, null, 8, ["content"])
  ]))
}