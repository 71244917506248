
import {defineComponent} from 'vue'
import PopNodeMarkerPopup from "@/components/map/children/popNodeMarkerPopup.vue";
import {LPopup} from "@vue-leaflet/vue-leaflet";
import {fiberPopNode} from "@/models/fiberPopNode";

export default defineComponent({
    name: "PopPopup",
    components: {LPopup, PopNodeMarkerPopup},
    props: {
        pop: {
            type: Object as () => fiberPopNode
        }
    }
})
