import {fiberSite} from "@/models/fiberSite";

export class ImportNoRowsFoundError extends Error {
    constructor() {
        super("The import file does not have any row entries to import.");

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ImportNoRowsFoundError.prototype);
    }
}

export class ImportColCountMismatchError extends Error {
    constructor() {
        super("The number of cols does not match the designated format.");

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ImportColCountMismatchError.prototype);
    }
}

export class ImportPopNotFound extends Error {
    constructor(pop_id) {
        super("The PoP with id '" + pop_id + "' cannot be found.")

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ImportPopNotFound.prototype);
    }
}

export class ImportInvalidSiteToken extends Error {
    constructor(token: string, index: number) {
        super("Invalid token for Site '" + token + "' around row '" + index +"'")

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ImportInvalidSiteToken.prototype);
    }
}

export class ImportInvalidLinkToken extends Error {
    constructor(token: string, index: number) {
        super("Invalid token for Link '" + token + "' around row '" + index +"'")

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ImportInvalidLinkToken.prototype);
    }
}
