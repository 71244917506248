<template>
    <div>
        <b-card no-body class="mb-1" v-if="show">
            <template #header>
                <div class="d-flex justify-content-between">
                    <span>POP: {{ selectedPop.name }}</span>
                    <bootstrap-icon icon="x" role="button" @click="close()"></bootstrap-icon>
                </div>
            </template>
            <b-card-text>
                <table class="table table-striped table-hover">
                    <tr>
                        <th>Pop ID</th>
                        <td>
                            <b-button variant="outline-secondary" size="sm" class="pt-1 pb-1 text-black"
                                      :id="'popover-target-'+selectedPop.pop_id"
                                      @click="copyToClipboard(selectedPop.pop_id, 'Copied Pop Node ID: ')">
                                <bootstrap-icon icon="star-fill"></bootstrap-icon>
                            </b-button>
                            <b-popover :target="'popover-target-'+selectedPop.pop_id" triggers="hover"
                                       placement="right">
                                <template #title>Pop Node ID</template>
                                {{ selectedPop.pop_id }}
                                <p class="text-muted">Click to Copy to Clipboard</p>
                            </b-popover>
                        </td>
                    </tr>
                    <tr>
                        <th>Token</th>
                        <td>
                            <token-badge :token="selectedPop.token" reference="node" class="pt-1 pb-1"></token-badge>
                        </td>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <td>{{ selectedPop.name }}</td>
                    </tr>
                    <tr>
                        <th>PIP</th>
                        <td>
                            {{ selectedPop.pip }}
                        </td>
                    </tr>
                    <tr>
                        <th>Center</th>
                        <td>
                            <b-button v-if="selectedPop.center" variant="outline-secondary" size="sm"
                                      class="pt-1 pb-1 text-black"
                                      :id="'popover-center-target-'+selectedPop.pop_id"
                                      @click="copyToClipboard(selectedPop.center, 'Copied Geo Position: ')">
                                <bootstrap-icon icon="geo-alt"></bootstrap-icon>
                            </b-button>
                            <b-popover :target="'popover-center-target-'+selectedPop.pop_id" triggers="hover"
                                       placement="top">
                                <template #title>Geo Position</template>
                                {{ selectedPop.center }}
                                <p class="text-muted">Click to Copy to Clipboard</p>
                            </b-popover>
                        </td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>
                           {{mapAddressShortFormat(selectedPop.address)}}
                        </td>
                    </tr>
                    <tr>
                        <th>Customer Ref.</th>
                        <td>{{ selectedPop.customer_reference }}</td>
                    </tr>
                    <tr>
                        <th>External ID</th>
                        <td>{{ selectedPop.external_id }}</td>
                    </tr>
                </table>
            </b-card-text>
            <template #footer>
                <b-button-group class="float-end">
                    <b-button size="sm" variant="outline-primary" @click="selectPop" title="go to Site on map">
                        <bootstrap-icon icon="geo-alt"></bootstrap-icon>
                    </b-button>
                    <b-button size="sm" variant="outline-primary" @click="showModal">
                        <bootstrap-icon icon="pencil"></bootstrap-icon>
                    </b-button>
                    <b-button size="sm" variant="outline-danger" @click="tryToDeleteNode">
                        <bootstrap-icon icon="trash"></bootstrap-icon>
                    </b-button>
                </b-button-group>
            </template>
        </b-card>

        <nodes-c-r-u-d-modal ref="nodesModal" :node="editItem"></nodes-c-r-u-d-modal>
        <confirm-modal ref="confirmModal" title="Are you sure?"
                       message="Do you want to remove the node?"
                       @confirmed-dialog="removeItem()"
                       variant="danger"
                       ok-button="remove"
                       cancel-button="close"></confirm-modal>
    </div>
</template>

<script>
import TokenBadge from "@/components/atoms/tokenBadge.vue";
import {toast} from "vue3-toastify";
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import NodesCRUDModal from "@/components/resources/nodes/nodesCRUDModal.vue";
import ConfirmModal from "@/components/confirmModal.vue";
import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {mapAddressShortFormat} from "../../../store/util/myFiberDataMapping";

export default {
    name: "nodeDetailView",
    components: {ConfirmModal, NodesCRUDModal, BootstrapIcon, TokenBadge},
    data() {
        return {
            fiberNodeStore: useFiberPopNodesStore(),
            editItem: useFiberPopNodesStore().selectedNode,
            show: true
        }
    },
    computed: {
        selectedPop() {
            return useFiberPopNodesStore().selectedNode
        }
    },
    watch: {
        selectedPop(latest) {
            if (latest != null) {
                this.editItem = latest
                this.show = true
            }
        }
    },
    methods: {
        mapAddressShortFormat,
        close() {
            this.show = false
            useFiberPopNodesStore().unsetSelectedNode()
        },
        showModal() {
            this.editItem = this.selectedPop
            this.$refs['nodesModal'].showModal()
        },
        tryToDeleteNode() {
            this.editItem = this.selectedPop
            this.$refs['confirmModal'].showModal()
        },
        removeItem() {
            this.fiberNodeStore.remove(this.editItem)
                .catch(error => {
                    toast(error, {
                        dangerouslyHTMLString: true,
                        position: toast.POSITION.BOTTOM_CENTER,
                        type: toast.TYPE.ERROR,
                        icon: true,
                        autoClose: false
                    });
                })
        },
        copyToClipboard(text, headline) {
            // eslint-disable-next-line no-undef
            navigator.clipboard.writeText(text)
            toast(headline + " \n <b>" + text + "</b>", {
                dangerouslyHTMLString: true,
                position: toast.POSITION.BOTTOM_CENTER,
                type: toast.TYPE.SUCCESS,
                icon: true,
                autoClose: 2000,
            });
        },
        selectPop() {
            useFiberPopNodesStore().setSelectedNode(this.selectedPop)
        }
    }
}

</script>

<style scoped>

.card-text {
    font-size: smaller;
}

.table tr {
    border-bottom: 1px solid #dee2e6;
}

.table tr:nth-child(even) {
    background: #efefef;
}

.table tr:last-of-type {
    border: none
}

.table th, .table td {
    padding: 4px 8px;
}
</style>
