<template>
    <div>
        <b-card no-body class="mb-1" v-if="link && show">
            <template #header>
                <div class="d-flex justify-content-between">
                    <span>Link Details</span>
                    <bootstrap-icon icon="x" role="button" @click="show=false"></bootstrap-icon>
                </div>
            </template>
            <b-card-text>
                <table class="table table-striped table-hover">
                    <tr>
                        <th>Link ID</th>
                        <td>
                            <b-button variant="outline-secondary" size="sm" :id="'popover-target-'+link.link_id" class="pt-1 pb-1 text-black"
                                      @click="copyToClipboard(link.link_id, 'Copied Link ID:')">
                                <bootstrap-icon icon="hdd-network"></bootstrap-icon>
                            </b-button>
                            <b-popover :target="'popover-target-'+link.link_id" triggers="hover" placement="right">
                                <template #title>Link ID</template>
                                {{ link.link_id }}
                                <p class="text-muted">Click to Copy to Clipboard</p>
                            </b-popover>
                        </td>
                    </tr>
                    <tr>
                        <th>Token</th>
                        <td>
                            <token-badge :token="link.token" reference="link"></token-badge>
                        </td>
                    </tr>
                    <tr>
                        <th>OAID</th>
                        <td>{{link.oaid}}</td>
                    </tr>
                    <tr>
                        <th>Fiber</th>
                        <td>{{link.fiber}}</td>
                    </tr>
                    <tr>
                        <th>Pop</th>
                        <td>{{link.popName}}</td>
                    </tr>
                    <tr>
                        <th>Contract</th>
                        <td>{{link.contract}}</td>
                    </tr>
                    <tr>
                        <th>PIP</th>
                        <td>{{link.pip}}</td>
                    </tr>

                    <tr>
                        <th>SID</th>
                        <td>
                            <b-button variant="outline-secondary" size="sm" :id="'popover-target-sid-'+link.link_id" class="pt-1 pb-1 text-black"
                                      @click="copyToClipboard(link.sid, 'Copied Site ID (SID):')">
                                <bootstrap-icon icon="person-fill"></bootstrap-icon>
                            </b-button>
                            <b-popover :target="'popover-target-sid-'+link.link_id" triggers="hover"
                                       placement="top">
                                <template #title>SID</template>
                                {{ link.sid }}
                                <p class="text-muted">Click to Copy to Clipboard</p>
                            </b-popover>
                        </td>
                    </tr>

                    <tr>
                        <th>Pop Position</th>
                        <td>
                            <template v-if="link.pop_position">
                                <bootstrap-split-badge headline="Frame" :content="link.pop_position.frame"
                                                       variant="secondary"></bootstrap-split-badge>
                                <br>
                                <bootstrap-split-badge headline="Patchpanel"
                                                       :content="link.pop_position.patchpanel"
                                                       variant="secondary"></bootstrap-split-badge>
                                <br>
                                <bootstrap-split-badge headline="Port" :content="link.pop_position.port"
                                                       variant="secondary"></bootstrap-split-badge>
                                <br>
                                <bootstrap-split-badge headline="Type" :content="link.pop_position.port_type"
                                                       variant="secondary"></bootstrap-split-badge>
                                <br>
                                <bootstrap-split-badge headline="Rack" :content="link.pop_position.rack"
                                                       variant="secondary"></bootstrap-split-badge>
                                <br>
                                <bootstrap-split-badge headline="Shelf" :content="link.pop_position.shelf"
                                                       variant="secondary"></bootstrap-split-badge>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <th>External Id</th>
                        <td>{{link.external_id}}</td>
                    </tr>
                </table>
            </b-card-text>
            <template #footer>
                <b-button-group class="float-end">
                    <b-button size="sm" variant="outline-primary" @click="showModal">
                        <bootstrap-icon icon="pencil"></bootstrap-icon>
                    </b-button>
                    <b-button size="sm" variant="outline-danger" @click="tryToDeleteLink">
                        <bootstrap-icon icon="trash"></bootstrap-icon>
                    </b-button>
                </b-button-group>
            </template>
        </b-card>

        <links-r-u-d-modal ref="linksModal" :link="currentLink"></links-r-u-d-modal>
        <confirm-modal variant="danger" ref="confirmModal" title="Are you sure?" message="Do you want to remove the link?"
                       @confirmed-dialog="this.fiberLinksStore.remove(link)" ok-button="remove"
                       cancel-button="close"></confirm-modal>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import TokenBadge from "@/components/atoms/tokenBadge.vue";
import BootstrapSplitBadge from "@/components/atoms/bootstrapSplitBadge.vue";
import LinksRUDModal from "@/components/resources/links/linksCRUDModal.vue";
import ConfirmModal from "@/components/confirmModal.vue";
import {toast} from "vue3-toastify";
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import {useFiberLinksStore} from "@/store/fiberLinksStore";

export default defineComponent({
    name: "linkDetailView",
    components: {BootstrapIcon, ConfirmModal, LinksRUDModal, BootstrapSplitBadge, TokenBadge},
    props: {
        link: Object
    },
    data() {
        return {
            fiberLinksStore: useFiberLinksStore(),
            displayModal: false,
            showOverlay: false,
            currentLink: this.link,
            show: true
        }
    },
    watch: {
        link(latest) {
            if(latest) {
                this.show = true
            }
        }
    },
    methods: {
        clickEdit() {
            this.$emit("edit-link-clicked", this.nlink.link_id)
        },
        showModal() {
            this.$emit("link-selected", this.link.link_id)
            this.currentNode = this.node
            this.$refs['linksModal'].showModal()
        },
        tryToDeleteLink() {
            this.$emit("link-selected", this.link.link_id)
            this.currentNode = this.node
            this.$refs['confirmModal'].showModal()
        },
        copyToClipboard(text, headline) {
            // eslint-disable-next-line no-undef
            navigator.clipboard.writeText(text)
            toast(headline + " \n <b>" + text + "</b>", {
                dangerouslyHTMLString: true,
                position: toast.POSITION.BOTTOM_CENTER,
                type: toast.TYPE.SUCCESS,
                icon: true,
                autoClose: 2000,
            });
        }
    }
})
</script>

<style scoped>

.card-text {
    font-size: smaller;
}

.table tr {
    border-bottom: 1px solid #dee2e6;
}

.table tr:nth-child(even) {
    background: #efefef;
}

.table tr:last-of-type {
    border: none
}

.table th, .table td {
    padding: 4px 8px;
}
</style>
