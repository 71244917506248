<template>
    <div :class="modalClass" tabindex="-1" id="sites-modal" role="dialog" :aria-hidden="!isVisible" :style="style">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit / Create Site: <b>OAID: {{ item.oaid }} -
                        {{ mapAddressShortFormat(item.address) }}</b></h5>
                    <b-button variant="primary" class="close" @click="hideModal()" :disabled="waitingForConfirmations">
                        <bootstrap-icon icon="x"></bootstrap-icon>
                    </b-button>
                </div>
                <div class="modal-body">
                    <b-row>
                        <b-col cols="12">
                            <b-alert variant="warning" v-model="showError" dismissible @dismissed="errorMessage=''"
                                     class="format-message" v-html="errorMessage"></b-alert>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="12">
                            <b-list-group>
                                <b-list-group-item>
                                    <b-form-group label="Token" label-for="token">
                                        <b-form-select v-model="item.token"
                                                       :options="tokenOptions"
                                                       id="token"></b-form-select>
                                    </b-form-group>
                                    <b-form-group label="PIP" label-for="pip">
                                        <b-form-select v-model="item.pip"
                                                       :options="pipOptions"
                                                       id="pip"></b-form-select>
                                    </b-form-group>
                                </b-list-group-item>
                                <b-list-group-item v-if="item.address">
                                    <b-alert variant="warning" :show="centerUnconfirmed">
                                        <p>
                                            <bootstrap-icon icon="exclamation-triangle-fill"
                                                            class="me-1"></bootstrap-icon>
                                            <b>The geo position changed and may not match with the address any
                                                longer.</b>
                                            <br>
                                            Confirm the change and the possible difference or reset the values.
                                        </p>
                                        <hr>
                                        <b-button-group class="d-flex align-content-end">
                                            <b-button variant="warning" class="me-2" @click="centerUnconfirmed=false">
                                                <bootstrap-icon icon="check-circle-fill"></bootstrap-icon>
                                                Confirm
                                            </b-button>
                                            <b-button variant="warning" class="me-2" @click="confirmAndSuggestAddress">
                                                <bootstrap-icon icon="house-down-fill"></bootstrap-icon>
                                                Confirm and Suggest Address
                                            </b-button>
                                            <b-button variant="warning" class="me-2" @click="resetCenter()">
                                                <bootstrap-icon icon="arrow-counterclockwise"></bootstrap-icon>
                                                Reset
                                            </b-button>
                                        </b-button-group>
                                    </b-alert>
                                    <b-form-group label="Center" label-for="center" id="geo-position">
                                        <b-input-group>
                                            <b-input-group-prepend>
                                                <b-button @click="geo=!geo" title="switch mode"
                                                          variant="outline-secondary">
                                                    <bootstrap-icon icon="toggles2" :flip-v="geo"></bootstrap-icon>
                                                </b-button>
                                            </b-input-group-prepend>
                                            <template v-if="geo">
                                                <b-input-group-text>GEO</b-input-group-text>
                                                <b-form-input v-model="item.center" type="text" id="center"
                                                              readonly
                                                              @click="copyToClipboard(item.center, 'Geoposition copied to Clipboard.')"></b-form-input>
                                            </template>
                                            <template v-else>
                                                <b-input-group-text>WKT</b-input-group-text>
                                                <b-form-input v-model="toPoint" type="text" id="center"
                                                              readonly
                                                              @click="copyToClipboard(toPoint, 'Geoposition copied to Clipboard.')"></b-form-input>
                                            </template>
                                            <b-input-group-append>
                                                <b-button variant="outline-primary" title="reset marker on map"
                                                          @click="activateResetMarker()">
                                                    <bootstrap-icon icon="geo-alt"></bootstrap-icon>
                                                </b-button>
                                                <b-button @click="suggestAddress" variant="outline-primary"
                                                          title="suggest address">
                                                    <bootstrap-icon icon="house-down-fill"></bootstrap-icon>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>

                                    </b-form-group>
                                    <b-alert variant="warning" :show="addressUnconfirmed">
                                        <p>
                                            <bootstrap-icon icon="exclamation-triangle-fill"
                                                            class="me-1"></bootstrap-icon>
                                            <b>The address changed and may not match with the geo position any
                                                longer.</b>
                                            <br>
                                            Confirm the change and the possible difference or reset the values.
                                        </p>
                                        <hr>
                                        <b-button-group class="d-flex align-content-end">
                                            <b-button variant="warning" class="me-2" @click="addressUnconfirmed=false">
                                                <bootstrap-icon icon="check-circle-fill"></bootstrap-icon>
                                                Confirm
                                            </b-button>
                                            <b-button variant="warning" class="me-2" @click="resetAddress">
                                                <bootstrap-icon icon="arrow-counterclockwise"></bootstrap-icon>
                                                Reset
                                            </b-button>
                                        </b-button-group>
                                    </b-alert>
                                    <b-form-group label="Street" label-for="street">
                                        <b-form-input v-model="item.address.street" type="text"
                                                      id="street"></b-form-input>
                                    </b-form-group>
                                    <b-form inline>
                                    <span class="d-flex justify-content-around">
                                        <label for="hnr">Number</label> <label for="door">Door</label>
                                    </span>
                                        <b-input-group>
                                            <b-form-input placeholder="" v-model="item.address.hnr" type="text"
                                                          id="hnr"></b-form-input>
                                            <b-form-input v-model="item.address.door" type="text"
                                                          id="door"></b-form-input>
                                        </b-input-group>
                                    </b-form>
                                    <br/>
                                    <b-form-group label="Village" label-for="village">
                                        <b-form-input v-model="item.address.village" type="text"
                                                      id="port"></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="city" label-for="country">
                                        <b-form-input v-model="item.address.city" type="text"
                                                      id="city" ></b-form-input>
                                    </b-form-group>
                                    <span class="d-flex justify-content-around">
                                        <label for="zip" class="w-25">Zip</label>
                                        <label for="city" class="w-75">Country</label>
                                    </span>
                                    <b-input-group class="mb-3">
                                        <b-form-input v-model="item.address.zip" type="text"
                                                      id="zip" class="w-25"></b-form-input>
                                        <b-form-input v-model="item.address.country" readonly type="text"
                                                      id="country" class="w-75"></b-form-input>
                                    </b-input-group>

                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                        <b-col md="6" sm="12">
                            <br class="d-block d-sm-none">
                            <b-list-group>
                                <b-list-group-item v-for="(link, lIndex) in item.links" :key="lIndex">
                                    <div class="d-flex justify-content-between ">
                                        <link-entry-view :link="link"></link-entry-view>
                                        <div v-if="!item.readonly">
                                            <b-button-group>
                                                <b-button size="sm" @click="showLinkModal(link)"
                                                          variant="outline-primary">
                                                    <bootstrap-icon icon="pencil"></bootstrap-icon>
                                                </b-button>
                                                <b-button size="sm" @click="tryToDeleteLink(link)"
                                                          variant="outline-danger">
                                                    <bootstrap-icon icon="trash"></bootstrap-icon>
                                                </b-button>
                                            </b-button-group>
                                        </div>
                                    </div>
                                </b-list-group-item>
                                <b-list-group-item style="text-align: right">
                                    <b-button variant="outline-primary" @click="openCreateLinkModal" size="sm"
                                              :disabled="!item.sid">
                                        <bootstrap-icon icon="node-plus"></bootstrap-icon>
                                        Add Link
                                    </b-button>
                                </b-list-group-item>
                            </b-list-group>
                            <br>
                            <b-list-group>
                                <b-list-group-item>
                                    Identification
                                    <br>
                                    <b-form-group v-if="!item.sid" label="OAID" label-for="oaid">
                                        <b-form-input v-model="item.oaid" type="text"
                                                      id="oaid" ></b-form-input>
                                    </b-form-group>
                                    <bootstrap-split-badge v-else variant="secondary" headline="OAID"
                                                           :content="item.oaid"></bootstrap-split-badge>
                                    <br>
                                    <bootstrap-split-badge variant="secondary" headline="Katastral Lot"
                                                           :content="item.lot_id"></bootstrap-split-badge>
                                </b-list-group-item>
                                <b-list-group-item>
                                    External Information
                                    <br>
                                    <bootstrap-split-badge variant="secondary" headline="External ID"
                                                           :content="item.external_id"></bootstrap-split-badge>
                                    <br>
                                    <bootstrap-split-badge variant="secondary" headline="Customer Reference"
                                                           :content="item.customer_reference"></bootstrap-split-badge>
                                </b-list-group-item>
                            </b-list-group>

                        </b-col>
                    </b-row>

                </div>
                <div class="modal-footer">
                    <button :disabled="waitingForConfirmations" type="button" class="btn btn-primary" v-if="!item.sid"
                            @click="create">Add item
                    </button>
                    <button :disabled="waitingForConfirmations" type="button" class="btn btn-primary" v-else
                            @click="update">Save changes
                    </button>
                    <button :disabled="waitingForConfirmations" type="button" class="btn btn-secondary"
                            @click="hideModal()" data-dismiss="modal">Close
                    </button>
                </div>
            </div>
        </div>
        <links-r-u-d-modal @item-created="addLink" ref="linksModal" :link="currentLink"
                           :popsList="popsWithDistance"></links-r-u-d-modal>
        <confirm-modal variant="danger" ref="confirmModal" title="Are you sure?"
                       message="Do you want to remove the link?"
                       @confirmed-dialog="fiberLinksStore.remove(currentLink)" ok-button="remove"
                       cancel-button="close"></confirm-modal>
        <div v-if="showLoader" id="overlay">
            <b-alert show variant="dark">
                Loading ...
            </b-alert>
        </div>
    </div>
</template>
<script>
import {useFiberSitesStore} from "@/store/fiberSitesStore";
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import {toast} from "vue3-toastify";
import {fiberSiteToken} from "@/models/fiberSite";
import {useFiberInfoStore} from "@/store/fiberInfoStore";
import BootstrapSplitBadge from "@/components/atoms/bootstrapSplitBadge.vue";
import LinksRUDModal from "@/components/resources/links/linksCRUDModal.vue";
import {useFiberPopNodesStore} from "@/store/fiberPopNodesStore";
import {mapListToFromSelect} from "@/components/utils/vueBootstrapDataStructureSupport";
import {mapAddressShortFormat, mapTokenToVariant} from "@/store/util/myFiberDataMapping";
import LinkEntryView from "@/components/resources/sites/linkEntryView.vue";
import ConfirmModal from "@/components/confirmModal.vue";
import {useFiberPipsStore} from "@/store/fiberPipsStore";
import {useDorisSearchStore} from "@/store/dorisSearchStore";
import {copyToClipboard} from "@/components/utils/usefullUserInteractions";
import {reactive} from "vue";
import {useInteractionStore} from "@/store/interactionStore";
import {useFiberLinksStore} from "@/store/fiberLinksStore";

export default {
    name: "sitesCRUDModal",
    components: {ConfirmModal, LinkEntryView, LinksRUDModal, BootstrapSplitBadge, BootstrapIcon},
    props: {
        modalId: String,
        site: Object
    },
    data() {
        return {
            siteStore: useFiberSitesStore(),
            infoStore: useFiberInfoStore(),
            nodeStore: useFiberPopNodesStore(),
            fiberLinksStore: useFiberLinksStore(),
            item: reactive({...this.site}),
            originalData: reactive({...this.site}),
            currentLink: {},
            isVisible: false,
            errorMessage: "",
            showLoader: false,
            addressUnconfirmed: false,
            centerUnconfirmed: false,
            geo: true
        }
    },
    watch: {
        'item.address': function (latest, older) {
            if (older && latest != older && this.isVisible) {
                this.addressUnconfirmed = true
            } else {
                this.addressUnconfirmed = false
            }
        },
        'item.center': function (latest, older) {
            if (older && latest != older && this.isVisible) {
                this.centerUnconfirmed = true
            } else {
                this.centerUnconfirmed = false
            }
        },
        site(latest) {
            if (latest) {
                this.item = reactive({...this.site})
                this.addressUnconfirmed = false
                this.centerUnconfirmed = false
            }
        },
        resetMarkerAction(latest) {

            if (latest) {
                this.isVisible = false
            } else {
                this.isVisible = true
            }

            if (useInteractionStore().newMarkerPosition != null && useInteractionStore().positionConfirmed) {
                this.item.center = useInteractionStore().newMarkerPosition
            }
        }
    },
    computed: {
        resetMarkerAction() {
            return useInteractionStore().resetMarkerAction
        },
        toPoint() {
            if (this.item.center != "") {
                return useDorisSearchStore().convertLatLngToPoint(this.item.center)
            } else {
                return this.item.center
            }

        },
        modalClass() {
            return "modal modal-primary modal-xl fade " + (this.isVisible ? "show" : "")
        },
        style() {
            return this.isVisible ? "display: block; background: rgba(0,0,0, 0.6); " : ""
        },
        newItem() {
            return this.item.sid == null
        },
        showError() {
            return this.errorMessage != ""
        },
        tokenOptions() {
            return Object.values(fiberSiteToken)
        },
        pipOptions() {
            return mapListToFromSelect(useFiberPipsStore().pipsList, 'pip', ['name'])
        },
        popsWithDistance() {
            if (typeof this.item.center != "undefined") {
                const centerPart = this.item.center.split(",")
                const geoPos = {
                    lat: parseFloat(centerPart[0]),
                    lng: parseFloat(centerPart[1])
                }

                return mapListToFromSelect(this.nodeStore.getSortedByDistance(geoPos), "pop_id", ["name", "distanceExpression"])
            } else {
                return []
            }
        },
        waitingForConfirmations() {
            return this.centerUnconfirmed || this.addressUnconfirmed
        }
    },
    methods: {
        mapAddressShortFormat,
        mapTokenToVariant,
        copyToClipboard,

        activateResetMarker() {
            useInteractionStore().activateMarkerAction()
        },
        addLink(eventData) {
            useFiberSitesStore().pushLink(this.item, eventData)
            this.item.links.push(eventData)
        },
        async update() {
            await this.siteStore.update(this.item)
                .then(() => {
                    this.hideModal()
                    this.$emit("send-message", "Updated item", "success")
                })
                .catch(error => {
                    this.errorMessage = error
                    this.item = this.originalData
                    document.getElementById('sites-modal').scrollTo(0, 0);
                })
        },
        async create() {
            this.showLoader = true
            await this.siteStore.create(this.item)
                .then(() => {
                    this.item.sid = this.siteStore.latest.sid
                    this.item.links = []
                    // this.hideModal()
                    this.$emit("send-message", "Create item", "success")

                    toast("Site was created", {
                        dangerouslyHTMLString: true,
                        position: toast.POSITION.BOTTOM_CENTER,
                        type: toast.TYPE.SUCCESS,
                        icon: true,
                        autoClose: 2000,
                    });

                    this.showLoader = false
                }).catch(error => {
                    this.errorMessage = error
                    document.getElementById('sites-modal').scrollTo(0, 0);
                    this.showLoader = false
                })
        },
        showModal() {
            this.isVisible = true
        },
        hideModal() {
            //reset
            //this.item = reactive({...this.originalData})
            this.isVisible = false
            useInteractionStore().deactivateMarkerAction()
            useInteractionStore().unsetNewMarkerPosition()
            useInteractionStore().setPositionConfirmed(false)
        },
        showLinkModal(item) {
            this.currentLink = item
            this.$refs['linksModal'].showModal()
        },
        tryToDeleteLink(item) {
            this.$emit("link-selected", item.link_id)
            this.currentLink = item
            this.$refs['confirmModal'].showModal()
        },
        openCreateLinkModal() {

            let linkModel = useFiberLinksStore().getEmptyLink()
            linkModel.sid = this.item.sid
            linkModel.constract = this.infoStore.contracts[0] //this.infoStore.pipNames[0], //todo: find nearest pop

            this.currentLink = linkModel
            this.$refs['linksModal'].showModal()
        },
        confirmAndSuggestAddress() {
            this.centerUnconfirmed = false
            this.suggestAddress()
        },
        async suggestAddress() {
            const addresses = await useDorisSearchStore().findByPosition(this.toPoint)
            if (addresses.length > 0) {
                this.item.address = addresses[0]
            } else {
                toast("No suggestions found", {
                    dangerouslyHTMLString: true,
                    position: toast.POSITION.BOTTOM_CENTER,
                    type: toast.TYPE.WARNING,
                    icon: true,
                    autoClose: 2000,
                });
            }
        },
        resetCenter() {
            this.item.center = this.site.center
            this.centerUnconfirmed = false
        },
        resetAddress() {
            this.item.address = reactive({...this.site.address})
            this.$nextTick(() => this.addressUnconfirmed = false)
        }
    }
}
</script>

<style scoped>
#overlay {
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(65, 65, 65, 0.7);
    width: 100%;
    height: 100%;

    cursor: not-allowed;

    padding-top: 500px;
    padding-left: 120px;
    padding-right: 120px;

    z-index: 2;
}

.format-message {
    white-space: pre;
    font-size: 12px;
}

.format-message:first-line {
    font-weight: bold;
    font-size: 14px;
}

.collapse-button.collapsed .when-open,
.collapse-button:not(.collapsed) .when-closed {
    display: none;
}

#geo-position .input-group-text, #geo-position input.form-control {
    border-color: #6c757d !important;
}
</style>
