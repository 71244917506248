<template>
    <div :class="modalClass" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
         :aria-hidden="!isVisible" :style="style">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">{{ title }}</h5>
                    <b-button :variant="variant" class="close" @click="hideModal()">
                        <span aria-hidden="true">×</span>
                    </b-button>
                </div>
                <div class="modal-body">
                    {{ message }}
                </div>
                <div class="modal-footer">
                    <button type="button" :class="'btn btn-'+variant" @click="confirmModal()">{{ okButton }}</button>
                    <button type="button" class="btn btn-secondary" @click="hideModal()" data-dismiss="modal">
                        {{ cancelButton }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "confirmModal",
    props: {
        title: String,
        message: String,
        okButton: String,
        cancelButton: String,
        variant: {
            type: String,
            default: "light",
        }
    },
    data: () => {
        return {
            isVisible: false
        }
    },
    computed: {
        modalClass() {
            let classString = "modal fade " + (this.isVisible ? "show" : "")
            switch (this.variant) {
                case "danger":
                    classString += " modal-danger";
                    break;
                case "success":
                    classString += " modal-success";
                    break;
                case "warning":
                    classString += " modal-warning";
                    break;
                case "info":
                    classString += " modal-info";
                    break;
                case "primary":
                    classString += " modal-primary";
                    break;
                case "secondary":
                    classString += " modal-secondary";
                    break;
            }
            return classString
        },
        style() {
            return this.isVisible ? "display: block; background: rgba(0,0,0, 0.6); " : ""
        }
    },
    methods: {
        showModal() {
            this.isVisible = true
        },
        hideModal() {
            this.isVisible = false
        },
        confirmModal() {
            this.$emit('confirmed-dialog')
            this.isVisible = false
        }
    }
}
</script>

<style scoped>


</style>
